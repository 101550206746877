import { slide as Menu } from "react-burger-menu";
import React from "react";

import "./burger-menu.styles.scss";

import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useTranslation, withTranslation } from "react-i18next";

import logo from "./leaf.svg";

class MenuBurger extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    const { t, i18n } = this.props;

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      //   <Menu customBurgerIcon={<img src={logo} />}>
      <Menu customBurgerIcon={<img src={logo} />}>
        <NavItem>
          <NavLink href="/azienda">{t("nav.azienda")}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/produzione">{t("nav.produzione")}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/catalogo">{t("nav.catalogo")}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/eventi">{t("nav.eventi")}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/contatti">{t("nav.contatti")}</NavLink>
        </NavItem>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {t("nav.lingua")}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => i18n.changeLanguage("it")}>
              ITA
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => i18n.changeLanguage("en")}>
              ENG
            </DropdownItem>
            <DropdownItem onClick={() => i18n.changeLanguage("fr")}>
              FRA
            </DropdownItem>
            <DropdownItem onClick={() => i18n.changeLanguage("de")}>
              DEU
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Menu>
    );
  }
}

export default withTranslation(["common"])(MenuBurger);
