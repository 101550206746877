import React from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import Example from "../../components/collapsable/collapsable.component";
import CarouselDetail from "../../components/carousel-detail-page/carousel-detail-page.component";
import SimpleSlider from "../../components/slick-carousel/carousel.component";
import CarouselSlicker from "../../components/carousel-detail-slicker/carousel-detail-slicker.component";
import { Link } from "react-router-dom";
import "./item-detail-page-plastica.styles.scss";

import ReactImageMagnify from "react-image-magnify";
import MediaQuery from "react-responsive";

import {
  CustomInput,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardHeader,
  Table,
} from "reactstrap";

import {
  faRulerVertical,
  faCircleNotch,
  faTree,
  faGlassWhiskey,
  faBook,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card } from "reactstrap";
import VasiCarousel from "../../components/vasi_carousel/vasi_carousel.component";
import Vasi from "../../components/vasi/vasi.components";
import Plastica from "../../components/plastica/plastica.component";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { ButtonGroup, ButtonToolbar } from "reactstrap";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CollapsableDetail from "../../components/collapsable/collapsable.component";
import { firestore } from "firebase";
import { db } from "../../firebase/firebase.utils";
import DetailRightAgrumi from "../../components/detail-right/detail-right-agrumi-component";
import DetailRightFicus from "../../components/detail-right/detail-right-ficus-component";

import DetailRightOlivi from "../../components/detail-right/detail-right-olivi.component";
import Tabs from "../../components/tabs/tabs.component";
import { Autocomplete } from "@material-ui/lab";


const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class ItemDetailPagePlastica extends React.Component {
  constructor() {
    super();
    this.state = {
      dettagli: [],
      varianti: [],
      photoUrl: "",
    };
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    db.collection("plastica")
      .doc(this.props.match.params.itemID)
      .get()
      .then((snapshot) => {
        const dettagli = {};
        // snapshot.forEach((doc) => {
        //   const data = doc.data();
        //   data.docID = doc.id;
        //   dettagli.push(data);
        // });
        const data = snapshot.data();
        this.setState({
          dettagli: data,
          photoUrl: data.photoUrl,
        });
      });
    const myvarianti = [];
    db.collection("plastica")
      .doc(this.props.match.params.itemID)
      .collection("varianti")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          myvarianti.push(doc.data());
        });
        // const varianti = {};
        // // snapshot.forEach((doc) => {
        // //   const data = doc.data();
        // //   data.docID = doc.id;
        // //   dettagli.push(data);
        // // });
        // const vars = snapshot.data();
        // this.setState({
        //   varianti: vars,
        // });
        this.setState({
          varianti: myvarianti,
        });
      });
  }

  render() {
    const {
      nome,
      altezza,
      dimensioni,
      oneSize,
      diametro,
    } = this.state.dettagli;
    const famiglia = "terracotta";

    return (
      <div className="item-detail-page">
        <div className="page-wrapper">
          <Container>
            <Row noGutters>
              <Col>
                <p style={{ marginTop: "24px", padding: "0 0" }}>
                  <Link to="/prodotti">
                    <span>Prodotti </span>
                  </Link>
                  <i class="fas fa-angle-right"></i>
                  <Link to="/prodotti/vasi/plastica">
                    <span> Plastica </span>
                  </Link>
                  <i class="fas fa-angle-right"></i>
                  <span> {nome}</span>
                </p>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="">
              <Col xs="auto" md="6" className="mx-auto">
                {(() => {
                  switch (famiglia) {
                    default:
                      return (
                        <img
                          className="plastica-image"
                          style={{
                            margin: "0 auto",
                          }}
                          alt="vaso-plastica"
                          src={this.state.photoUrl}
                        />
                      );
                  }
                })()}
              </Col>
              <Col xs="auto" md="6">
                <div className="detailWrap">
                  <p
                    className="title larger nocenter nome"
                    style={{
                      marginBottom: "0",
                      fontSize: "2.0rem",
                      color: "rgba(0,0,0,0.45)",
                    }}
                  >
                    {nome}
                  </p>
                  <hr
                    className=""
                    style={{
                      margin: "0",
                      width: "85%",
                      color: "rgba(0,0,0,0.45)",
                    }}
                  ></hr>

                  <br></br>
                  <div>
                    <p
                      style={{ fontFamily: "Montserrat", lineHeight: "1.5" }}
                    ></p>
                  </div>

                  {(() => {
                    switch (this.props.match.params.prodottiID) {
                      default:
                        return (
                          <div>
                            {/* <div className="Field">
                              <p className="title nocenter">Altezza Vaso</p>
                              <p className="dettaglio">{altezza} cm</p>
                            </div>
                            <div className="Field">
                              <p className="title nocenter">Diametro</p>
                              <p className="dettaglio">{diametro} cm</p>
                            </div> */}

                            <h4 style={{ color: "rgba(0,0,0,0.5)" }}>
                              Disponibile nelle dimensioni
                            </h4>
                            <div className="Field">
                              <Table hover bordered>
                                <thead>
                                  <tr>
                                    <th>Diametro</th>
                                    <th>Altezza</th>
                                    <th>Codice</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.varianti.map(
                                    ({ altezza, diametro, codice }) => (
                                      <tr>
                                        <td>{diametro} cm</td>
                                        <td>{altezza} cm</td>
                                        <td>{codice}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        );
                    }
                  })()}
                </div>
              </Col>
            </Row>
          </Container>

          <MediaQuery query="(max-width: 650px)">
            <Container style={{ margin: "0", padding: "0" }} fluid>
              <div
                className="wrapper"
                style={{
                  marginTop: "50px",
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/contatti_mobile.jpg"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    marginBottom: "0px",
                  }}
                  alt=""
                />

                <div
                  class="centered"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div className="contattaci-text" style={{}}>
                    Vuoi maggiori informazioni su questo prodotto?
                  </div>
                  <Link to="/contatti">
                    <Button
                      size="sm"
                      style={{ marginTop: "5px" }}
                      className="btn-success"
                    >
                      CONTATTACI
                    </Button>
                  </Link>
                </div>
              </div>
            </Container>
          </MediaQuery>

          <MediaQuery query="(min-width: 1024px)">
            <Container style={{ margin: "0", padding: "0" }} fluid>
              <div
                className="wrapper"
                style={{
                  marginTop: "50px",
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/contatti_desktop.jpg"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    marginBottom: "0px",
                  }}
                  alt=""
                />

                <div
                  class="centered"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div className="contattaci-text" style={{}}>
                    Vuoi maggiori informazioni su questo prodotto?
                  </div>
                  <Link to="/contatti">
                    <Button
                      size="sm"
                      style={{ marginTop: "5px" }}
                      className="btn-success"
                    >
                      CONTATTACI
                    </Button>
                  </Link>
                </div>
              </div>
            </Container>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default ItemDetailPagePlastica;
