import { faDivide } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./vasi.styles.scss";
import { Spring } from "react-spring/renderprops";

import { Card, Container, Row, Col } from "reactstrap";
import DemoCarousel from "../owlcarousel/owlcarousel.component";

import "react-multi-carousel/lib/styles.css";
import VasiCarousel from "../vasi_carousel/vasi_carousel.component";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var responsive = {
  0: {
    items: 2,
  },
  700: {
    items: 3,
  },
  900: {
    items: 5,
  },
  1200: {
    items: 5,
  },
  2000: {
    items: 5,
  },
};

const Vasi = ({ varianti, currentPhoto, item, oneSize, action }) => {
  console.log("ACTION");
  console.log(action);

  return (
    <Spring
      config={{ duration: 1000 }}
      from={{ opacity: 0.2 }}
      to={{ opacity: 1 }}
    >
      {(props) => (
        <div style={props}>
          <Container>
            <Row>
              <Col>
                <p
                  className=""
                  style={{
                    marginTop: "12px",
                    fontSize: "1.5rem",
                    fontFamily: "Poppins",
                    letterSpacing: "0.5",
                    color: "rgba(0, 0, 0, 0.5)",
                    textAlign: "center",
                  }}
                >
                  Scegli il tuo vaso in terracotta
                </p>
              </Col>
            </Row>
          </Container>

          <Container style={{ padding: "0 20%" }}>
            <Row className="align-items-center">
              <OwlCarousel nav responsive={responsive} className="owl-theme">
                {varianti.map(({ photoVaso, photoUrl }) => (
                  <div
                    style={{
                      borderRadius: "3px",
                      width: "120px",
                      height: "120px",
                    }}
                    className="VasoItemWrapper"
                    // onClick={action()}
                  >
                    <img src={photoVaso} alt="" />
                  </div>
                ))}
              </OwlCarousel>
            </Row>
          </Container>
        </div>
      )}
    </Spring>
  );
};

export default Vasi;
