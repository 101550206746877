import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./produzione.styles.scss";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";

const Produzione = (props) => {
  const { t } = useTranslation("common");

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    pauseOnFocus: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="produzione">
      <div className="page-wrapper">
        <Container>
          <h3
            style={{
              fontWeight: "700",
              fontSize: "32px",
              fontFamily: "Ubuntu",
              // fontFamily: "Catamaran",
              color: "#17733A",
            }}
          >
            {t("nav.produzione")}
          </h3>
        </Container>

        <Container>
          <Row>
            <Col md="12" xs="auto">
              <p style={{ fontFamily: "Roboto", fontStyle: "italic" }}>
                {t("produzione_page.intro")}
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col
              sm={{ size: "auto" }}
              md={{ size: 4 }}
              style={{ marginBottom: "45px" }}
            >
              <p
                style={{
                  marginBottom: "8px",
                  textAlign: "unset",
                  fontFamily: "Catamaran,sans-serif",
                  fontWeight: "700",
                  borderLeft: "3px solid #17733a",
                  fontSize: "1.2rem",
                  paddingLeft: "1rem",
                }}
                className="title"
              >
                {t("produzione_page.fase1_title")}
              </p>
              <p>{t("produzione_page.fase1_body")}</p>
            </Col>
            <Col style={{ marginBottom: "45px" }} sm="auto" md="8">
              <Slider {...settings} style={{ margin: "0 5%" }}>
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_riproduzione1.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_riproduzione2.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_riproduzione3.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_riproduzione4.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_riproduzione5.jpg"
                  alt=""
                />
              </Slider>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ marginBottom: "45px" }}
              sm={{ size: "auto" }}
              md={{ size: 4 }}
            >
              <p
                className="title"
                style={{
                  marginBottom: "8px",
                  textAlign: "unset",
                  fontFamily: "Catamaran,sans-serif",
                  fontWeight: "700",
                  borderLeft: "3px solid #17733a",
                  fontSize: "1.2rem",
                  paddingLeft: "1rem",
                }}
              >
                {/* <i style={{ marginRight: "5px" }} class="fas fa-caret-right"></i> */}
                {t("produzione_page.fase2_title")}
              </p>
              <p>{t("produzione_page.fase2_body")}</p>
            </Col>
            <Col style={{ marginBottom: "45px" }} sm="auto" md="8">
              <Slider {...settings} style={{ margin: "0 5%" }}>
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_affrancamento1.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_affrancamento2.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_affrancamento3.jpg"
                  alt=""
                />
              </Slider>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col
              sm={{ size: "auto" }}
              md={{ size: 4 }}
              style={{ marginBottom: "45px" }}
            >
              <p
                style={{
                  marginBottom: "8px",
                  textAlign: "unset",
                  fontFamily: "Catamaran,sans-serif",
                  fontWeight: "700",
                  borderLeft: "3px solid #17733a",
                  fontSize: "1.2rem",
                  paddingLeft: "1rem",
                }}
                className="title"
              >
                {/* <i style={{ marginRight: "5px" }} class="fas fa-caret-right"></i> */}
                {t("produzione_page.fase3_title")}
              </p>
              <p>{t("produzione_page.fase3_body")}</p>
            </Col>
            <Col style={{ marginBottom: "45px" }} sm="auto" md="8">
              <Slider {...settings} style={{ margin: "0 5%" }}>
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_coltivazione1.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_coltivazione2.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_coltivazione3.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_coltivazione4.jpg"
                  alt=""
                />
              </Slider>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ marginBottom: "45px" }}
              sm={{ size: "auto" }}
              md={{ size: 4 }}
            >
              <p
                className="title"
                style={{
                  marginBottom: "8px",
                  textAlign: "unset",
                  fontFamily: "Catamaran,sans-serif",
                  fontWeight: "700",
                  borderLeft: "3px solid #17733a",
                  fontSize: "1.2rem",
                  paddingLeft: "1rem",
                }}
              >
                {/* <i style={{ marginRight: "5px" }} class="fas fa-caret-right"></i> */}
                {t("produzione_page.fase4_title")}
              </p>
              <p>{t("produzione_page.fase4_body")}</p>
            </Col>
            <Col style={{ marginBottom: "45px" }} sm="auto" md="8">
              <Slider {...settings} style={{ margin: "0 5%" }}>
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_consegna1.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_consegna2.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_consegna3.jpg"
                  alt=""
                />
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/produzione_consegna4.jpg"
                  alt=""
                />
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default withRouter(withTranslation(["common"])(Produzione));
