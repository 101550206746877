import React from "react";
import "./item-detail-page.styles.scss";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../components/vasi/vasi.styles.scss";

import ReactImageMagnify from "react-image-magnify";
import MediaQuery from "react-responsive";

import { Button } from "reactstrap";

import Vasi from "../../components/vasi/vasi.components";
import { db } from "../../firebase/firebase.utils";
import { Spring } from "react-spring/renderprops";
import { UncontrolledCollapse, CardBody, Card } from "reactstrap";
import ReactTooltip from "react-tooltip";
import Tooltip from "@material-ui/core/Tooltip";

var responsive = {
  0: {
    items: 3,
  },
  700: {
    items: 3,
  },
  900: {
    items: 5,
  },
  1200: {
    items: 5,
  },
  2000: {
    items: 5,
  },
};

var responsivePlastica = {
  0: {
    items: 3,
  },
  700: {
    items: 3,
  },
  900: {
    items: 3,
  },
  1200: {
    items: 4,
  },
  2000: {
    items: 4,
  },
};

var famiglia = "";
const pianta = "olivi";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class ItemDetailPage extends React.Component {
  constructor() {
    super();
    this.state = {
      currentSrc: "",
      currentQuantitaPianale: "",
      currentQuantitaCarrello: "",
      selectedOption: "",
      dettagli: [],
      variantiTerracotta: [],
      myVariantiPlastica: [],
      myCorrelati: [],
      currentPhoto: "",
      plasticaLenght: 0,
      terracottaLenght: 0,
      noVarianti: false,
      potName: "",
    };
    this.onChangeValue = this.onChangeValue.bind(this);

    this.handler = this.handler.bind(this);
  }

  handler(photoUrl) {
    console.log("hit" + photoUrl);
    this.setState({
      currentPhoto: photoUrl,
    });
  }

  onLoad = (event) => {
    this.setState({
      currentSrc: event.target.currentSrc,
    });
  };

  onChangeValue(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.match.url.includes("alberetti")) {
      famiglia = "alberetti";
    } else if (this.props.match.url.includes("cespugli")) {
      famiglia = "cespugli";
    } else {
      famiglia = "bonsai";
    }
    db.collection("olivi")
      .doc(this.props.match.params.itemID)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        this.setState({
          dettagli: data,
          currentPhoto: data.photoUrl,
          currentQuantitaCarrello: data.quantitaCarrello,
          currentQuantitaPianale: data.quantitaPianale,
          potName: data.potName,
        });
        switch (data.vasi) {
          case 0:
            break;
          case 1:
            this.setState({ selectedOption: "plastica" });
            break;
          case 2:
            this.setState({
              selectedOption: "terracotta",
            });
            break;
          case 3:
            this.setState({
              selectedOption: "terracotta",
            });
            break;
          default:
            break;
        }
      });
    const myvarianti = [];
    const myVariantiPlastica = [];
    const myCorrelati = [];
    db.collection("olivi")
      .doc(this.props.match.params.itemID)
      .collection("varianti")
      .where("tipo", "==", "terracotta")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          myvarianti.push(doc.data());
        });
      });
    db.collection("olivi")
      .doc(this.props.match.params.itemID)
      .collection("varianti")
      .where("tipo", "==", "plastica")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          myVariantiPlastica.push(doc.data());
        });
      });

    //TODO ADD DIVERSO DA ATTUALE
    db.collection("olivi")
      .limit(4)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          myCorrelati.push(doc.data());
        });
      });

    this.setState({
      variantiTerracotta: myvarianti,
      myVariantiPlastica: myVariantiPlastica,
      myCorrelati: myCorrelati,
      plasticaLenght: myVariantiPlastica.length,
      terracottaLenght: myvarianti.length,
    });
  }

  render() {
    const {
      nome,
      altezza,
      chioma,
      dimensioni,
      vasi,
      potName,
    } = this.state.dettagli;
    const { t } = this.props;
    var urlFamiglia = "/prodotti/olivi/" + famiglia;

    return (
      <div className="item-detail-page" style={{ marginBottom: "32px" }}>
        <div className="page-wrapper">
          <Container>
            <Row noGutters>
              <Col>
                <p style={{ marginTop: "24px", padding: "0 0" }}>
                  <Link to="/prodotti">
                    <span>Prodotti </span>
                  </Link>
                  <i class="fas fa-angle-right"></i>
                  <Link to="/prodotti/olivi">
                    <span> Olivi </span>
                  </Link>
                  <i class="fas fa-angle-right"></i>
                  <Link to={urlFamiglia}>
                    <span> {capitalize(famiglia)} </span>
                  </Link>
                  <i class="fas fa-angle-right"></i>
                  <span> {nome}</span>
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="justify-content-center">
              <Col xs="auto" md="6" className="align-self-center">
                {(() => {
                  switch (pianta) {
                    case "olivi":
                      return (
                        <div>
                          {/* //MOBILE */}
                          <MediaQuery query="(max-width: 650px)">
                            <ReactImageMagnify
                              style={{ margin: "auto" }}
                              {...{
                                enlargedImagePosition: "over",
                                smallImage: {
                                  alt: "",
                                  src: this.state.currentPhoto,
                                  height: 300,
                                  width: 200,
                                },
                                largeImage: {
                                  src: this.state.currentPhoto,
                                  height: 450,
                                  width: 300,
                                },
                              }}
                            />
                          </MediaQuery>
                          {/* //PC OR TABLET */}
                          <MediaQuery query="(min-width: 1024px)">
                            <ReactImageMagnify
                              style={{ margin: "auto" }}
                              {...{
                                enlargedImagePosition: "over",
                                smallImage: {
                                  alt: "",
                                  src: this.state.currentPhoto,
                                  height: 450,
                                  width: 300,
                                },
                                largeImage: {
                                  src: this.state.currentPhoto,
                                  height: 900,
                                  width: 600,
                                },
                              }}
                            />
                          </MediaQuery>
                        </div>
                      );
                    default:
                      return (
                        <ReactImageMagnify
                          className="main-img"
                          {...{
                            enlargedImagePosition: "over",
                            smallImage: {
                              alt: "",
                              src: this.state.currentPhoto,
                              isFluidWidth: true,
                            },
                            largeImage: {
                              src: this.state.currentPhoto,
                              width: 1080,
                              height: 1080,
                            },
                          }}
                        />
                      );
                  }
                })()}
              </Col>
              <Col xs="auto" md="6">
                <div className="detailWrap">
                  <p
                    className="title larger nocenter nome"
                    style={{ marginBottom: "0", fontSize: "2.0rem" }}
                  >
                    {nome}
                  </p>
                  <p
                    className="title larger nocenter nome"
                    style={{ marginBottom: "0", fontSize: "2.0rem" }}
                  >
                    {this.state.potName}
                  </p>
                  <hr
                    className="solid"
                    style={{ margin: "0", width: "85%" }}
                  ></hr>

                  <br></br>
                  <div>
                    <p
                      style={{ fontFamily: "Montserrat", lineHeight: "1.5" }}
                    ></p>
                  </div>

                  {(() => {
                    switch (this.props.match.params.prodottiID) {
                      default:
                        return (
                          <div>
                            <MediaQuery query="(max-width: 650px)">
                              <div>
                                <Button
                                  color="success"
                                  id="toggler"
                                  // style={{ marginBottom: "1rem" }}
                                >
                                  Mostra dettagli prodotto
                                </Button>
                                <UncontrolledCollapse toggler="#toggler">
                                  <Card>
                                    <CardBody>
                                      <div className="Field first">
                                        <p className="title nocenter">
                                          {t(
                                            "item_detail_right_section.plant_height"
                                          )}
                                        </p>
                                        <p className="dettaglio">
                                          {altezza} cm
                                        </p>
                                        {/* <hr className="solid" /> */}
                                      </div>
                                      <div className="Field">
                                        <p className="title nocenter">
                                          {t(
                                            "item_detail_right_section.diameter"
                                          )}
                                        </p>
                                        <p className="dettaglio">
                                          {dimensioni} cm
                                        </p>
                                      </div>
                                      <div className="Field">
                                        <p className="title nocenter">
                                          {t("item_detail_right_section.shape")}
                                        </p>
                                        <p className="dettaglio">{chioma}</p>
                                      </div>
                                      <div className="Field">
                                        <p className="title nocenter">
                                          {t(
                                            "item_detail_right_section.quantity_per_trolley"
                                          )}
                                        </p>
                                        <p className="dettaglio">
                                          {this.state.currentQuantitaPianale}
                                        </p>
                                      </div>
                                      <div className="Field">
                                        <p className="title nocenter">
                                          {t(
                                            "item_detail_right_section.quantity_per_shelf"
                                          )}
                                        </p>
                                        <p className="dettaglio">
                                          {this.state.currentQuantitaCarrello}
                                        </p>
                                        {(() => {
                                          switch (vasi) {
                                            case 0:
                                              return <div></div>;
                                              break;
                                            case 1:
                                              return (
                                                <div
                                                  className="buttons"
                                                  style={{ marginTop: "16px" }}
                                                >
                                                  <Button
                                                    disabled
                                                    outline
                                                    color="success"
                                                    onClick={() => {
                                                      this.setState({
                                                        selectedOption:
                                                          "plastica",
                                                      });
                                                    }}
                                                    className=""
                                                  >
                                                    Plastica
                                                  </Button>
                                                </div>
                                              );
                                              break;
                                            case 2:
                                              return (
                                                <div
                                                  className="buttons"
                                                  style={{ marginTop: "16px" }}
                                                >
                                                  <Button
                                                    disabled
                                                    outline
                                                    color="success"
                                                    onClick={() => {
                                                      this.setState({
                                                        selectedOption:
                                                          "terracotta",
                                                      });
                                                    }}
                                                    className=""
                                                  >
                                                    Terracotta
                                                  </Button>
                                                </div>
                                              );
                                              break;
                                            case 3:
                                              return (
                                                <div
                                                  className="buttons"
                                                  style={{ marginTop: "16px" }}
                                                >
                                                  <Button
                                                    outline
                                                    color="success"
                                                    size="md"
                                                    onClick={() => {
                                                      this.setState({
                                                        selectedOption:
                                                          "terracotta",
                                                      });
                                                    }}
                                                    className=" mr-3"
                                                  >
                                                    {t("pot_type.terracotta")}
                                                  </Button>
                                                  <Button
                                                    outline
                                                    size="md"
                                                    color="success"
                                                    onClick={() => {
                                                      this.setState({
                                                        selectedOption:
                                                          "plastica",
                                                      });
                                                    }}
                                                    className=" ml-3"
                                                  >
                                                    {t(
                                                      "common:pot_type.plastica"
                                                    )}
                                                  </Button>
                                                </div>
                                              );
                                            default:
                                              return <div></div>;
                                          }
                                        })()}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </UncontrolledCollapse>
                              </div>
                            </MediaQuery>
                            <MediaQuery query="(min-width: 1024px)">
                              <div className="Field first">
                                <p className="title nocenter">
                                  {t("item_detail_right_section.plant_height")}
                                </p>
                                <p className="dettaglio">{altezza} cm</p>
                                {/* <hr className="solid" /> */}
                              </div>
                              <div className="Field">
                                <p className="title nocenter">
                                  {t("item_detail_right_section.diameter")}
                                </p>
                                <p className="dettaglio">{dimensioni} cm</p>
                              </div>
                              <div className="Field">
                                <p className="title nocenter">
                                  {t("item_detail_right_section.shape")}
                                </p>
                                <p className="dettaglio">{chioma}</p>
                              </div>
                              <div className="Field">
                                <p className="title nocenter">
                                  {t(
                                    "item_detail_right_section.quantity_per_trolley"
                                  )}
                                </p>
                                <p className="dettaglio">
                                  {this.state.currentQuantitaPianale}
                                </p>
                              </div>
                              <div className="Field">
                                <p className="title nocenter">
                                  {t(
                                    "item_detail_right_section.quantity_per_shelf"
                                  )}
                                </p>
                                <p className="dettaglio">
                                  {this.state.currentQuantitaCarrello}
                                </p>

                                {(() => {
                                  switch (vasi) {
                                    case 0:
                                      return <div></div>;
                                      break;
                                    case 1:
                                      return (
                                        <div
                                          className="buttons"
                                          style={{ marginTop: "16px" }}
                                        >
                                          <Button
                                            disabled
                                            outline
                                            color="success"
                                            onClick={() => {
                                              this.setState({
                                                selectedOption: "plastica",
                                              });
                                            }}
                                            className=""
                                          >
                                            Plastica
                                          </Button>
                                        </div>
                                      );
                                      break;
                                    case 2:
                                      return (
                                        <div
                                          className="buttons"
                                          style={{ marginTop: "16px" }}
                                        >
                                          <Button
                                            disabled
                                            outline
                                            color="success"
                                            onClick={() => {
                                              this.setState({
                                                selectedOption: "terracotta",
                                              });
                                            }}
                                            className=""
                                          >
                                            Terracotta
                                          </Button>
                                        </div>
                                      );
                                      break;
                                    case 3:
                                      return (
                                        <div
                                          className="buttons"
                                          style={{ marginTop: "16px" }}
                                        >
                                          <Button
                                            outline
                                            color="success"
                                            size="md"
                                            onClick={() => {
                                              this.setState({
                                                selectedOption: "terracotta",
                                              });
                                            }}
                                            className=" mr-3"
                                          >
                                            {t("pot_type.terracotta")}
                                          </Button>
                                          <Button
                                            outline
                                            size="md"
                                            color="success"
                                            onClick={() => {
                                              this.setState({
                                                selectedOption: "plastica",
                                              });
                                            }}
                                            className=" ml-3"
                                          >
                                            {t("common:pot_type.plastica")}
                                          </Button>
                                        </div>
                                      );
                                    default:
                                      return <div></div>;
                                  }
                                })()}

                                {/* {pianta === "olivi" ? (
                                  <div
                                    className="buttons"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <Button
                                      outline
                                      color="success"
                                      size="md"
                                      onClick={() => {
                                        this.setState({
                                          selectedOption: "terracotta",
                                        });
                                      }}
                                      className=" mr-3"
                                    >
                                      {t("pot_type.terracotta")}
                                    </Button>
                                    <Button
                                      outline
                                      size="md"
                                      color="success"
                                      onClick={() => {
                                        this.setState({
                                          selectedOption: "plastica",
                                        });
                                      }}
                                      className=" ml-3"
                                    >
                                      {t("common:pot_type.plastica")}
                                    </Button>
                                  </div>
                                ) : (
                                  <Button
                                    disabled
                                    outline
                                    color="success"
                                    onClick={() => {
                                      this.setState({
                                        selectedOption: "plastica",
                                      });
                                    }}
                                    className=""
                                  >
                                    Plastica
                                  </Button>
                                )} */}
                              </div>
                            </MediaQuery>
                          </div>
                        );
                    }
                  })()}

                  <div
                    className="Field vasoSelect"
                    // style={{ marginBottom: "50px" }}
                  >
                    {/* <p
                      className="title nocenter"
                      style={{ marginBottom: "10px", textAlign: "" }}
                    >
                      {t("item_detail_right_section.pot_type")}
                    </p> */}

                    {/* <div className="centerItems text-center"> */}

                    {/*        <div className="">
                      {pianta === "olivi" ? (
                        <div className="">
                          <Button
                            outline
                            color="success"
                            size="md"
                            onClick={() => {
                              this.setState({
                                selectedOption: "terracotta",
                              });
                            }}
                            className=" mr-3"
                          >
                            {t("pot_type.terracotta")}
                          </Button>
                          <Button
                            outline
                            size="md"
                            color="success"
                            onClick={() => {
                              this.setState({
                                selectedOption: "plastica",
                              });
                            }}
                            className=" ml-3"
                          >
                            {t("common:pot_type.plastica")}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          disabled
                          outline
                          color="success"
                          onClick={() => {
                            this.setState({
                              selectedOption: "plastica",
                            });
                          }}
                          className=""
                        >
                          Plastica
                        </Button>
                      )}
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col xs="12">
                <div className="vasiWrapper">
                  {/* {this.state.selectedOption === "terracotta" ? (
                  <Vasi></Vasi>
                ) : (
                  <Plastica></Plastica>
                )} */}
                  {(() => {
                    switch (this.state.selectedOption) {
                      case "terracotta":
                        if (this.props.prodottiID === "olivi") {
                          return (
                            <Vasi
                              action={this.handler.bind(this)}
                              varianti={this.state.variantiTerracotta}
                              currentPhoto={this.state.currentPhoto}
                              item={this.props.match.params.prodottiID}
                            />
                          );
                        } else {
                          return (
                            // <Vasi
                            //   varianti={this.state.variantiTerracotta}
                            //   item={this.props.match.params.prodottiID}
                            //   photoUrl={this.state.currentPhoto}
                            //   oneSize={oneSize}
                            // />
                            <div>
                              <Container>
                                <Row>
                                  <Col xs="12">
                                    <div className="vasiWrapper">
                                      <Spring
                                        config={{ duration: 1000 }}
                                        from={{ opacity: 0.2 }}
                                        to={{ opacity: 1 }}
                                      >
                                        {(props) => (
                                          <div style={props}>
                                            <Container>
                                              <Row>
                                                <Col>
                                                  <p className="choose-pot">
                                                    {t(
                                                      "choose_your_pot.terracotta"
                                                    )}
                                                  </p>
                                                </Col>
                                              </Row>
                                            </Container>

                                            <Container
                                              className="carousel-container"
                                              style={{ padding: "0 20%" }}
                                            >
                                              <Row className="align-items-center">
                                                <OwlCarousel
                                                  // autoWidth
                                                  margin={5}
                                                  // startPosition={2}
                                                  nav
                                                  responsive={responsive}
                                                  className="owl-theme"
                                                >
                                                  {this.state.variantiTerracotta.map(
                                                    ({
                                                      photoVaso,
                                                      photoUrl,
                                                      quantitaCarrello,
                                                      quantitaPianale,
                                                      nome,
                                                      docID,
                                                      potName,
                                                    }) => (
                                                      <Tooltip
                                                        title={nome}
                                                        placement="top"
                                                        style={{
                                                          fontSize:
                                                            "20px !important",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            borderRadius: "3px",
                                                          }}
                                                          className="VasoItemWrapper"
                                                          onClick={() => {
                                                            this.setState({
                                                              currentPhoto: photoUrl,
                                                              currentQuantitaCarrello: quantitaCarrello,
                                                              currentQuantitaPianale: quantitaPianale,
                                                              potName: potName,
                                                            });
                                                            window.scrollTo(
                                                              0,
                                                              0
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={photoVaso}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </Tooltip>
                                                    )
                                                  )}
                                                </OwlCarousel>
                                              </Row>
                                            </Container>
                                          </div>
                                        )}
                                      </Spring>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          );
                        }
                      case "plastica":
                        return (
                          <Container>
                            <Row>
                              <Col xs="12">
                                <div className="vasiWrapper">
                                  <Spring
                                    config={{ duration: 1000 }}
                                    from={{ opacity: 0.2 }}
                                    to={{ opacity: 1 }}
                                  >
                                    {(props) => (
                                      <div style={props}>
                                        <Container>
                                          <Row>
                                            <Col>
                                              <p
                                                className="choose-pot"
                                                // style={{
                                                //   marginTop: "12px",

                                                //   fontSize: "1.5rem",
                                                //   fontFamily: "Poppins",
                                                //   letterSpacing: "0.5",
                                                //   color: "rgba(0, 0, 0, 0.5)",
                                                //   textAlign: "center",
                                                // }}
                                              >
                                                {t("choose_your_pot.plastic")}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Container>

                                        <Container
                                          className="carousel-container"
                                          style={{ padding: "0 25%" }}
                                        >
                                          <Row className="align-items-center">
                                            <OwlCarousel
                                              nav
                                              responsive={responsivePlastica}
                                              className="owl-theme"
                                              margin={5}
                                            >
                                              {this.state.myVariantiPlastica.map(
                                                ({
                                                  photoVaso,
                                                  photoUrl,
                                                  quantitaCarrello,
                                                  quantitaPianale,
                                                  potName,
                                                }) => (
                                                  <div
                                                    onClick={() => {
                                                      this.setState({
                                                        currentPhoto: photoUrl,
                                                        currentQuantitaCarrello: quantitaCarrello,
                                                        currentQuantitaPianale: quantitaPianale,
                                                        potName: potName,
                                                      });
                                                      //TODO only on mobile, when loaded
                                                      window.scrollTo(0, 0);
                                                    }}
                                                    style={{
                                                      borderRadius:
                                                        "3px !important",
                                                    }}
                                                    className="VasoItemWrapper"
                                                  >
                                                    <img
                                                      src={photoVaso}
                                                      alt=""
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </OwlCarousel>
                                          </Row>
                                        </Container>
                                      </div>
                                    )}
                                  </Spring>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        );
                      default:
                      // return (
                      //   <p className="choose-pot">
                      //     Questo prodotto è disponibile soltanto con il vaso
                      //     in foto
                      //   </p>
                      // );
                    }
                  })()}
                </div>
              </Col>
            </Row>
          </Container>

          <MediaQuery query="(max-width: 650px)">
            <Container style={{ margin: "0", padding: "0" }} fluid>
              <div
                className="wrapper"
                style={{
                  marginTop: "50px",
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/contatti_mobile.jpg"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    marginBottom: "0px",
                  }}
                  alt=""
                />

                <div
                  class="centered"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div
                    className="contattaci-text"
                    style={{ padding: "10px 10px" }}
                  >
                    Vuoi maggiori informazioni su questo prodotto?
                  </div>
                  <Link to="/contatti">
                    <Button
                      size="sm"
                      style={{ marginTop: "5px" }}
                      className="btn-success"
                    >
                      CONTATTACI
                    </Button>
                  </Link>
                </div>
              </div>
            </Container>
          </MediaQuery>

          <MediaQuery query="(min-width: 1024px)">
            <Container style={{ margin: "0", padding: "0" }} fluid>
              <div
                className="wrapper"
                style={{
                  marginTop: "50px",
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <img
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/contatti_desktop.jpg"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    marginBottom: "0px",
                  }}
                  alt=""
                />

                <div
                  class="centered"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div className="contattaci-text" style={{}}>
                    Vuoi maggiori informazioni su questo prodotto?
                  </div>
                  <Link to="/contatti">
                    <Button
                      size="sm"
                      style={{ marginTop: "5px" }}
                      className="btn-success"
                    >
                      CONTATTACI
                    </Button>
                  </Link>
                </div>
              </div>
            </Container>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(ItemDetailPage));
