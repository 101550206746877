import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import "./owlcarousel.styles.scss";
import { Button } from "reactstrap";
import CarouselItem from "../carouselItem/carouselItem.component";
import "./vasi_carousel.styles.scss";
import VasoCarouselItem from "../vaso_carousel_item/vaso_carousel_item.component";

class VasiCarousel extends React.Component {
  state = {
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 2,
      },
      800: {
        items: 3,
      },
      1200: {
        items: 5,
      },
      1600: {
        items: 5,
      },
    },
  };
  render() {
    return (
      <div>
        <div class="container-fluid">
          <div className="row title" style={{ marginBottom: "20px" }}></div>
        </div>

        <div class="container-fluid">
          <OwlCarousel
            nav
            responsive={this.state.responsive}
            className="owl-theme"
            margin={8}
          >
            {/* <VasoCarouselItem
              className="vaso-carousel-item"
              photoUrl="https://i.ibb.co/P5vBD19/01.jpg"
              titolo="Vaso 1"
              testo=""
            ></VasoCarouselItem> */}

            <VasoCarouselItem
              className="vaso-carousel-item"
              // photoUrl={varianti[0].photoUrl}
              titolo="Vaso 2"
              testo=""
            ></VasoCarouselItem>

            {/* <VasoCarouselItem
              className="vaso-carousel-item"
              photoUrl="https://i.ibb.co/5hgYTfF/03.jpg"
              titolo="Vaso 3"
              testo=""
            ></VasoCarouselItem> */}
            <VasoCarouselItem
              className="vaso-carousel-item"
              photoUrl="https://i.ibb.co/xDwS2xS/03-removebg-preview.jpg"
              titolo="Vaso 1"
            ></VasoCarouselItem>
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default VasiCarousel;
