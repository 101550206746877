import { withRouter, Route, Link } from "react-router-dom";
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { db } from "../../firebase/firebase.utils";
import { Container, Row, Col, CardBody, Collapse, Button } from "reactstrap";
import Item from "../../components/item/item.component";

class Alberetti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prodotti: [],
      tag: this.props.match.params.prodottoID,
    };
  }

  componentDidMount() {
    db.collection("olivi")
      .where("chioma", "==", "Alberetto")
      .get()
      .then((snapshot) => {
        const prodotti = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.docID = doc.id;
          prodotti.push(data);
        });
        this.setState({
          prodotti: prodotti,
        });
      });
  }

  render() {
    return (
      <div className="cespugli" style={{ marginTop: "80px" }}>
        <Container>
          <Row noGutters>
            <Col>
              <p style={{ marginTop: "24px", padding: "0 0" }}>
                <Link to="/prodotti">
                  <span>Prodotti </span>
                </Link>
                <i class="fas fa-angle-right"></i>
                <Link to="/prodotti/olivi">
                  <span> Olivi </span>
                </Link>
                <i class="fas fa-angle-right"></i>
                <span> Alberetti</span>
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="cards">
          <Row>
            {this.state.prodotti.map(({ photoUrl, nome, docID }) => (
              <Item
                key={docID}
                docID={docID}
                photoUrl={photoUrl}
                dettaglio={nome}
                linkable
              ></Item>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(Alberetti));
