import React from "react";
import { CustomInput } from "reactstrap";
import "./custom-card.styles.scss";
import { withRouter } from "react-router-dom";

const CustomCard = ({
  photoUrl,
  nome,
  dettaglio,
  docID,
  linkable,
  history,
  match,
}) => (
  <div
    onClick={() => history.push(`${match.url}${"/" + docID}`)}
    className="item-wrapper"
  >
    {/* <img className="item-img" src={photoUrl} alt="" /> */}
    <div
      className="item-image"
      style={{
        backgroundImage: `url(${photoUrl})`,
      }}
    />
    <div className="subtitle">
      <p className="subtitle-text">{dettaglio}</p>
    </div>
  </div>
);

export default withRouter(CustomCard);
