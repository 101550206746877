import React, { useState, Suspense } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import "./bootstrap-header.styles.scss";

import Logo from "./logo.svg";
import Logo2 from "./logo2.svg";
import Logo3 from "./logo3.svg";
import Logo4 from "./logo4.svg";
import Logo6 from "./logo6.svg";
import logoIpsum from "./logoIpsum.svg";

const BootStrapHeader = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const [t, i18n] = useTranslation("common");

  return (
    <Navbar className="navbar-light fixed-top" color="" expand="md">
      <NavbarBrand href="/">
        <img
          className="logo-img"
          src={Logo6}
          // logo6 remove height
          alt="logo"
        ></img>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="/azienda">{t("nav.azienda")}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/produzione">{t("nav.produzione")}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/prodotti">{t("nav.prodotti")}</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="/eventi">{t("nav.eventi")}</NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink href="/contatti">{t("nav.contatti")}</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar className="mr-5">
            <DropdownToggle nav caret>
              {t("nav.lingua")}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => i18n.changeLanguage("it")}>
                ITA
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => i18n.changeLanguage("en")}>
                ENG
              </DropdownItem>
              <DropdownItem onClick={() => i18n.changeLanguage("fr")}>
                FRA
              </DropdownItem>
              <DropdownItem onClick={() => i18n.changeLanguage("de")}>
                DEU
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default BootStrapHeader;
