import React from "react";
import { Button } from "reactstrap";
import "./vaso_carousel_item.styles.scss";

const VasoCarouselItem = ({ titolo, testo, photoUrl }) => {
  return (
    <div>
      <div className="VasoItemWrapper">
        <img
          className="img"
          alt=""
          src={photoUrl}
          style={{ borderRadius: "3px" }}
        />
        {/* <p
          style={{
            marginBottom: "0px",
            fontFamily: "Lato",
            textTransform: "uppercase",
          }}
          className="title VasoCarouselTitle"
        >
          {titolo}
        </p> */}
      </div>
    </div>
  );
};

export default VasoCarouselItem;
