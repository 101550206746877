import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.styles.css";
export default class SimpleSlider extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: true,
    };
    return (
      <div className="myslider" style={{ margin: "30px 20px" }}>
        <Slider {...settings}>
          <div>
            <img
              className="img-responsive-carousel"
              src="https://i.ibb.co/0qvTSzS/2.jpg.jpg"
              alt="Photo1"
            />
          </div>
          <div>
            <img
              className="img-responsive-carousel"
              src="https://i.ibb.co/Xx9fN0p/img-2.jpg"
              alt="Photo2"
            />
          </div>
          <div>
            <img
              className="img-responsive-carousel"
              src="https://i.ibb.co/xzL9w5T/3.jpg.jpg"
              alt="Photo3"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
