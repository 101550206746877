import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "./catalogo.styles.scss";
import Item from "../../components/item/item.component";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CategoriaOlivo from "../categoriaOlivo/categoriaOlivo.component";
import ReactTooltip from "react-tooltip";

//TODO fix mediaQuery labels

import {
  firestore,
  convertCollectionSnapshotToMap,
} from "../../firebase/firebase.utils";
import { Autocomplete } from "@material-ui/lab";

class Catalogo extends React.Component {
  constructor(props) {
    super(props);
  }
  // componentDidMount() {
  //   const collectionRef = firestore.collection("prodotti");
  //   collectionRef.onSnapshot(async (snapshot) => {});
  // }

  render() {
    const { t, i18n, history, match } = this.props;

    return (
      <div className="catalogo">
        <div className="wrapper">
          <Container>
            <Row>
              <Col className="my-col" md="12">
                <div className="title-container">
                  <h3
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      fontFamily: "Ubuntu",
                      color: "#17733A",
                    }}
                    className="title larger mb-3"
                  >
                    {t("catalogo.title")}
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            {/* <Row className="justify-content-center align-items-center"> */}
            <Row className="justify-content-center align-items-center">
              <Col className="my-col mb-5" xs="4" md="3">
                <div
                  className="category-wrapper"
                  onClick={() => {
                    history.push(`${match.url}${"/olivi"}`);
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                    }}
                    className="img-responsive clickable"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/copertina-olivi.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">{t("catalogo.olivi")}</span>
                  </div>
                </div>
              </Col>

              <Col className="my-col mb-5" xs="4" md="3">
                <div
                  className="category-wrapper"
                  style={{
                    position: "relative",
                    textAlign: "center",
                    color: "white",
                  }}

                  // onClick={() => history.push(`${match.url}${"/agrumi"}`)}
                >
                  <span
                    style={{ textTransform: "uppercase" }}
                    className="not-available"
                  >
                    {t("coming_soon.text")}
                  </span>
                  <img
                    className="img-responsive no-hover"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/copertina-agrumi.jpg"
                    alt="Photo5"
                    style={{
                      // filter: "grayscale(100%)",
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                    }}
                  />

                  <div className="category-label noclickable">
                    <span className="text-label">{t("catalogo.agrumi")}</span>
                  </div>
                </div>
              </Col>
              <Col className="my-col mb-5" xs="4" md="3">
                <div
                  className="category-wrapper"
                  // onClick={() => history.push(`${match.url}${"/ficus"}`)}
                  style={{
                    position: "relative",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <span
                    style={{ textTransform: "uppercase" }}
                    className="not-available"
                  >
                    {t("coming_soon.text")}
                  </span>
                  <img
                    className="img-responsive no-hover"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/copertina-fichi.jpg"
                    alt="Photo8"
                    style={{
                      // filter: "grayscale(100%)",
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                    }}
                  />
                  <div className="category-label noclickable">
                    <span className="text-label">Ficus</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col className="my-col" md="12">
                <div className="title-container">
                  <h3
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      fontFamily: "Ubuntu",
                      color: "#17733A",
                    }}
                    className="title larger mb-3"
                  >
                    {t("catalogo.pots")}
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="justify-content-center align-items-center">
              <Col className="my-col mb-5" xs="4" md="3">
                <div
                  className="category-wrapper"
                  onClick={() =>
                    history.push(`${match.url}${"/vasi/terracotta"}`)
                  }
                >
                  <img
                    style={{
                      //-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                      //filter: "grayscale(100%)
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                      border: "0.5px solid rgba(0,0,0,0.05)",
                    }}
                    className="img-responsive clickable"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/copertina-terracotta.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">
                      {t("pot_type.terracotta")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="my-col mb-5" xs="4" md="3">
                <div
                  className="category-wrapper"
                  onClick={() =>
                    history.push(`${match.url}${"/vasi/plastica"}`)
                  }
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                      border: "0.5px solid rgba(0,0,0,0.05)",
                    }}
                    className="img-responsive clickable"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/copertina-plastica.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">{t("pot_type.plastica")}</span>
                  </div>
                </div>
              </Col>
              <Col className="my-col mb-5" xs="4" md="3"></Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(Catalogo));
