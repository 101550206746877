const photoPlantReducer = (state = { photo: "" }, action) => {
  switch (action.type) {
    case "CHANGEPOT":
      return (state.photo = action.payload);
    default:
      return state;
  }
};

export default photoPlantReducer;
