import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CustomCard from "../custom-card/custom-card.component";
import "./owlcarousel.styles.scss";
import { Button } from "reactstrap";
import CarouselItem from "../carouselItem/carouselItem.component";
import { Link } from "react-router-dom";

class DemoCarousel extends React.Component {
  state = {
    responsive: {
      0: {
        items: 3,
      },
      700: {
        items: 3,
      },
      900: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  render() {
    return (
      <div>
        <div class="container-fluid">
          <div className="row title" style={{ marginBottom: "20px" }}></div>
        </div>

        <div class="container-fluid" style={{ margin: "20px 0" }}>
          <OwlCarousel
            nav
            responsive={this.state.responsive}
            className="owl-theme"
            navText={[
              "<i class='fa fa-chevron-left'></i>",
              "<i class='fa fa-chevron-right'></i>",
            ]}
          >
            <Link to="/prodotti/olivi/cespugli/OC07">
              <div className="item-carousel-home" style={{ margin: "0 10px" }}>
                <img
                  style={{ borderRadius: "3px" }}
                  className="img-responsive"
                  src="https://i.ibb.co/j5gcXM3/DS7-1000x1500.jpg"
                  alt="OC07"
                />
              </div>
            </Link>

            <Link to="/prodotti/olivi/alberetti/OA17">
              <div className="item-carousel-home" style={{ margin: "0 10px" }}>
                <img
                  style={{ borderRadius: "3px" }}
                  className="img-responsive"
                  src="https://i.ibb.co/LzqxyDK/OA17-IP16-copia.jpg"
                  alt="OA17"
                />
              </div>
            </Link>
            <Link to="/prodotti/olivi/bonsai/OTBC21">
              <div className="item-carousel-home" style={{ margin: "0 10px" }}>
                <img
                  style={{ borderRadius: "3px" }}
                  className="img-responsive"
                  src="https://i.ibb.co/8B3Zpjd/Copia-di-OTBC21-TC21.jpg"
                  alt="OTBC21"
                />
              </div>
            </Link>
            <Link to="/prodotti/olivi/alberetti/OA53">
              <div className="item-carousel-home" style={{ margin: "0 10px" }}>
                <img
                  style={{ borderRadius: "3px" }}
                  className="img-responsive"
                  src="https://i.ibb.co/2gNMzJ5/IMG-1611.jpg"
                  alt=""
                />
              </div>
            </Link>
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default DemoCarousel;
