import React from "react";
import "./custom-button.styles.scss";
import { useTranslation } from "react-i18next";

const CustomButton = ({ children, ...otherProps }) => {
  const [t] = useTranslation("common");

  return (
    <button className="custom-button" {...otherProps}>
      {t("button.text")}
    </button>
  );
};

export default CustomButton;
