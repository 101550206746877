import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";

const config = {
  // apiKey: "AIzaSyACnBzhAhUBujE_IO21eLKsN3kOH2Zvihw",
  // authDomain: "vivaiananiareactbootstrap.firebaseapp.com",
  // databaseURL: "https://vivaiananiareactbootstrap.firebaseio.com",
  // projectId: "vivaiananiareactbootstrap",
  // storageBucket: "vivaiananiareactbootstrap.appspot.com",
  // messagingSenderId: "232557825935",
  // appId: "1:232557825935:web:c1edea0c0865a9352585fa",
  // measurementId: "G-ZH822SWV73",
  apiKey: "AIzaSyDNeMferFAgNZvJRrbjo3NeL1hbNJdvz-Y",
  authDomain: "vivaianania-prod.firebaseapp.com",
  projectId: "vivaianania-prod",
  databaseURL: "https://vivaianania-prod.firebaseio.com",
  storageBucket: "vivaianania-prod.appspot.com",
  messagingSenderId: "309117226823",
  appId: "1:309117226823:web:c5d273f9ecbbdcef53c662",
  measurementId: "G-35H8ND9014",
};

firebase.initializeApp(config);

export const auth = firebase.auth();

export const db = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithGoogle(provider);

export default firebase;
