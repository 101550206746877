import React from "react";
import "./App.css";
import Header from "./components/header/header.component";
//PAGINE
import Homepage from "./pages/homepage/homepage.component";
import Azienda from "./pages/azienda/azienda.component";
import Produzione from "./pages/produzione/produzione.component";
import Catalogo from "./pages/catalogo/catalogo.component";
import Eventi from "./pages/eventi/eventi.component";
import Contatti from "./pages/contatti/contatti.component";
import BootStrapHeader from "./components/bootstrap-header/bootstrap-header.component";
import ItemDetailPage from "./pages/item-detail-page/item-detail-page.component";
import CategoriaOlivo from "./pages/categoriaOlivo/categoriaOlivo.component";
import { Switch, Route, Link } from "react-router-dom";
import Footer from "./components/footer/footer.component";
import Prefooter from "./components/pre-footer/pre-footer.component";
import { useTranslation } from "react-i18next";
import CustomButton from "./components/custom-button/custom-button.component";
import i18next from "i18next";
import ContattaciFooter from "./components/contattaci-footer/contattaci-footer.component";
import Categoria from "./pages/categorie/olivi/categoria.component";
import Condizioniduso from "./pages/condizioniduso/condizioniduso.component";
import MenuBurger from "./components/burger-menu/burger-menu.component";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import Cespugli from "./pages/cespugli/cespugli.component";
import Alberetti from "./pages/alberetti/alberetti.component";
import Bonsai from "./pages/bonsai/bonsai.component";
import Vasi from "./pages/vasi/vasi.component";
import Terracotta from "./pages/terracotta/terracotta.component";
import Plastica from "./pages/plastica/plastica.component";
import ItemDetailPageTerracotta from "./pages/item-detail-page-terracotta/item-detail-page-terracotta.component";
import ItemDetailPagePlastica from "./pages/item-detail-page-plastica/item-detail-page-plastica.component";

function App() {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isRetina = useMediaQuery({ minResolution: "2dppx" });
  return (
    <div className="App">
      <BootStrapHeader />
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/azienda" exact component={Azienda} />
        <Route path="/produzione" exact component={Produzione} />
        <Route path="/prodotti" exact component={Catalogo} />
        {/* <Route path="/eventi" exact component={Eventi} /> */}
        <Route path="/contatti" exact component={Contatti} />
        <Route path="/prodotti" exact component={ItemDetailPage} />
        <Route path="/prodotti/olivi" exact component={CategoriaOlivo} />
        <Route path="/prodotti/vasi" exact component={Vasi} />
        <Route path="/prodotti/vasi/terracotta" exact component={Terracotta} />
        <Route path="/condizioniduso" exact component={Condizioniduso} />
        <Route
          path="/prodotti/vasi/terracotta/:itemID"
          exact
          component={ItemDetailPageTerracotta}
        />
        <Route path="/prodotti/vasi/plastica" exact component={Plastica} />
        <Route
          path="/prodotti/vasi/plastica/:itemID"
          exact
          component={ItemDetailPagePlastica}
        />
        <Route path="/prodotti/olivi/cespugli" exact component={Cespugli} />
        <Route
          path="/prodotti/olivi/cespugli/:itemID"
          exact
          component={ItemDetailPage}
        />
        <Route path="/prodotti/olivi/alberetti" exact component={Alberetti} />
        <Route
          path="/prodotti/olivi/alberetti/:itemID"
          exact
          component={ItemDetailPage}
        />
        <Route path="/prodotti/olivi/bonsai" exact component={Bonsai} />
        <Route
          path="/prodotti/olivi/bonsai/:itemID"
          exact
          component={ItemDetailPage}
        />
        <Route path="/prodotti/:prodottoID" component={Categoria} />

        {/* Questo Funziona */}

        <Route
          path="/prodotti/:prodottiID/:itemID"
          exact
          component={ItemDetailPage}
        />
      </Switch>
      {/* <ContattaciFooter /> */}
      {/* <Prefooter/> */}
      <Footer />
    </div>
  );
}

export default App;
