import React from "react";
import Contact from "../../components/contact/contact.component";
import { Container, Row, Col } from "reactstrap";
import FormExample from "../../components/contact-form/contact-form.component";
import { useTranslation, withTranslation } from "react-i18next";
import "./contatti.styles.scss";
import { SocialIcon } from "react-social-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEnvelope,
  faMagic,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

class Contatti extends React.Component {
  // const [t, i18n] = useTranslation("common");

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="page-wrapper">
        <div className="contatti">
          <Container>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "32px",
                fontFamily: "Ubuntu",
                color: "#17733A",
              }}
            >
              {t("nav.contatti")}
            </h3>
          </Container>
          <Container>
            <Row>
              <Col md="6">
                <p style={{ marginTop: "8px" }}>
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    color="green"
                    icon={faPhone}
                  />
                  +39 090932859
                </p>
                <p>
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    color="green"
                    icon={faEnvelope}
                  />
                  vivaianania@vivaianania.it
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "start",
                    justifyItems: "start",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <SocialIcon
                    url="https://wa.me/message/G734GH2GKKNLE1"
                    target="_blank"
                    className="single-social"
                    style={{ height: 24, width: 24, marginRight: "8px" }}
                    network="whatsapp"
                    bgColor="#25d366"
                  />
                  <p style={{ marginBottom: "0" }}>
                    <a
                      className="social-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/message/G734GH2GKKNLE1"
                    >
                      https://wa.me/message/G734GH2GKKNLE1
                    </a>
                  </p>
                </div>

                <p className="title nocenter" style={{ fontSize: "20px" }}>
                  Compila il form
                </p>
                <FormExample />
              </Col>
              <Col className="" md="6">
                <img
                  style={{
                    margin: "auto 0",
                    width: "100%",
                    height: "auto",
                    minHeight: "700px",
                    objectFit: "cover",
                  }}
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/contatti.jpg"
                  alt=""
                />
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="justify-content-center">
              <Col className="align-self-center" xs="auto" md="8">
                <div style={{ margin: "50px 0" }}>
                  <p className="title larger" style={{ textAlign: "center" }}>
                    Seguici sui nostri social
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <SocialIcon
                    url="https://wa.me/message/G734GH2GKKNLE1"
                    target="_blank"
                    className="single-social"
                    style={{ height: 35, width: 35, margin: "10px 10px" }}
                    network="whatsapp"
                    bgColor="#25d366"
                  />
                  <p style={{ marginBottom: "0" }}>
                    <a
                      className="social-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/message/G734GH2GKKNLE1"
                    >
                      https://wa.me/message/G734GH2GKKNLE1
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <SocialIcon
                    url="https://www.facebook.com/vivaianania"
                    target="_blank"
                    className="single-social"
                    style={{ height: 35, width: 35, margin: "10px 10px" }}
                    network="facebook"
                    bgColor="#3b5998"
                  />
                  <p style={{ marginBottom: "0" }}>
                    <a
                      className="social-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/vivaianania"
                    >
                      https://www.facebook.com/vivaianania
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <SocialIcon
                    className="single-social"
                    style={{ height: 35, width: 35, margin: "10px 10px" }}
                    network="instagram"
                    bgColor="#E1456C"
                    url="https://www.instagram.com/vivai_anania/?hl=it"
                    target="_blank"
                  />
                  <p style={{ marginBottom: "0" }}>
                    <a
                      className="social-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/vivai_anania/?hl=it"
                    >
                      https://www.instagram.com/vivai_anania
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="justify-content-center">
              <Col className="align-self-center" md="12"></Col>
            </Row>
          </Container>

          <Container style={{ marginTop: "100px" }}>
            <Row>
              <Col md="12">
                <div
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "24px",
                  }}
                >
                  <h1
                    style={{
                      color: "rgb(23, 115, 58)",
                      fontFamily: "Poppins",
                      fontSize: "24px",
                    }}
                  >
                    DOVE SIAMO
                  </h1>
                  <div style={{ marginTop: "2rem" }}>
                    <p>Viale Sicilia II, Vico n° 3</p>
                    <p>98057 - Milazzo (ME)</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid style={{ padding: "0 15%" }}>
            <Row>
              <Col md="12">
                <div style={{ margin: "32px 0" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3136.05641771368!2d15.263021915329517!3d38.18536357968689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x131431707872bf0b%3A0x6f047afb57e8146c!2sAzienda%20Agricola%20Anania%20Patrizia%20%26%20C.%20Sas!5e0!3m2!1sit!2sit!4v1603198454889!5m2!1sit!2sit"
                    width="100%"
                    height="600"
                    frameborder="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(Contatti));
