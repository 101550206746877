import React from "react";
import CustomButton from "../custom-button/custom-button.component";
import "./contact-form.styles.scss";

import {
  Trans,
  Translate,
  Translation,
  useTranslation,
  withTranslation,
} from "react-i18next";

import FormInput from "../../components/form-input/form-input.component";
import AlertExample from "../alert/alert.component";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../text-validator/text-validator.component";

import { init } from "emailjs-com";
import i18next from "i18next";
init("user_Gf4gjGc7lRFlLzR1ZGCDi");

const serviceID = "service_ylh480e";
const templateID = "template_v05ge3i";

const templateParams = {
  reply_to: "",
  from_name: "",
  message: "",
  oggetto: "",
};

class FormExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      email: "",
      testo: "",
      oggetto: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="group">
        <ValidatorForm
          validate
          // instantValidate={true}
          className="test-mailing"
          ref="form"
          onSubmit={this.handleSubmit}
        >
          {/* <form > */}
          <TextValidator
            style={{ marginBottom: "16px" }}
            name="nome"
            value={this.state.nome}
            onChange={this.handleChange}
            placeholder={t("placeholder.nome")}
            // validators={["minStringLength:1"]}
            // errorMessages={[t("validators.required")]}
          />
          <TextValidator
            placeholder="Email"
            style={{ marginBottom: "8px" }}
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            // validators={["minStringLength:1"]}
            // errorMessages={[t("validators.required"), t("validators.email")]}
          />

          <TextValidator
            placeholder={t("placeholder.oggetto")}
            name="oggetto"
            type="text"
            value={this.state.oggetto}
            onChange={this.handleChange}
            // validators={["minStringLength:1"]}
            // errorMessages={[t("validators.required")]}
          />
          <div className="group">
            <textarea
              className="form-input"
              name="testo"
              onChange={this.handleChange}
              placeholder={t("placeholder.testo")}
              value={this.state.testo}
              style={{ width: "100%", height: "150px" }}
            />
          </div>

          <CustomButton
            type="submit"
            value="Submit"
            onClick={this.handleSubmit}
          >
            INVIA
          </CustomButton>
        </ValidatorForm>

        {/* </form> */}
        {this.state.success === true ? <AlertExample /> : null}
      </div>
    );
  }

  handleChange = async (event) => {
    const { value, name } = event.target;
    await this.setState({ [name]: value });
    templateParams.reply_to = this.state.email;
    templateParams.from_name = this.state.nome;
    templateParams.message = this.state.testo;
    templateParams.subject = this.state.oggetto;
  };

  handleSubmit(event) {
    event.preventDefault();

    const templateId = "template_v05ge3i";
    this.sendFeedback(templateId, {
      message_html: this.state.testo,
      from_name: this.state.nome,
      reply_to: this.state.email,
      subject: this.state.oggetto,
    });

    this.setState({
      nome: "",
      email: "",
      testo: "",
      oggetto: "",
    });
  }

  sendFeedback(templateId) {
    window.emailjs
      .send(serviceID, templateID, templateParams)
      .then((res) => {})
      // Handle errors here however you like, or use a React error boundary
      .catch((err) =>
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        )
      );
  }
}

export default withTranslation(["common"])(FormExample);
