import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button, Col, Row } from "reactstrap";
import CarouselItem from "../carouselItem/carouselItem.component";
import "./plastica_carousel.styles.scss";
import VasoCarouselItem from "../vaso_carousel_item/vaso_carousel_item.component";

class PlasticaCarousel extends React.Component {
  state = {
    responsive: {
      0: {
        items: 3,
      },
      700: {
        items: 3,
      },
      900: {
        items: 3,
      },
      1200: {
        items: 5,
      },
      2000: {
        items: 5,
      },
    },
  };
  render() {
    const { data } = this.state;
    return (
      <div>
        <div class="container-fluid">
          <div className="row title" style={{ marginBottom: "20px" }}></div>
        </div>

        <div class="container-fluid">
          <Row className="">
            <Col>
              <div style={{ margin: "0 auto" }}>
                <OwlCarousel
                  nav
                  responsive={this.state.responsive}
                  className="owl-theme"
                  // loop
                  margin={8}
                >
                  <VasoCarouselItem
                    className="vaso-carousel-item"
                    photoUrl="https://i.ibb.co/VVXYHHQ/VASO-12-verde-button.jpg"
                    titolo="Vaso 1"
                    testo=""
                  ></VasoCarouselItem>

                  <VasoCarouselItem
                    className="vaso-carousel-item"
                    photoUrl="https://i.ibb.co/BG90mnh/VASO-20-arancio-button.jpg"
                    titolo="Vaso 2"
                    testo=""
                  ></VasoCarouselItem>
                  <VasoCarouselItem
                    className="vaso-carousel-item"
                    photoUrl="https://i.ibb.co/rcBdvjv/VASO-26-tc-button.jpg"
                    titolo="Vaso 2"
                    testo=""
                  ></VasoCarouselItem>
                  <VasoCarouselItem
                    className="vaso-carousel-item"
                    photoUrl="https://i.ibb.co/FV5vLc8/VASO-26-verde-button.jpg.jpg"
                    titolo="Vaso 2"
                    testo=""
                  ></VasoCarouselItem>
                  <VasoCarouselItem
                    className="vaso-carousel-item"
                    photoUrl="https://i.ibb.co/vHdryKx/CIOTOLA-21-verde-button.jpg"
                    titolo="Vaso 2"
                    testo=""
                  ></VasoCarouselItem>
                  <VasoCarouselItem
                    className="vaso-carousel-item"
                    photoUrl="https://i.ibb.co/BfzGN54/Senza-titolo-1.jpg"
                    titolo="Vaso 2"
                    testo=""
                  ></VasoCarouselItem>
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PlasticaCarousel;
