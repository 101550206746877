import React, { useState } from "react";
import SimpleSlider from "../../components/slick-carousel/carousel.component";
import "./homepage.styles.css";
import Social from "../../components/social/social.component";
import Footer from "../../components/footer/footer.component";
import CustomCard from "../../components/custom-card/custom-card.component";
import DemoCarousel from "../../components/owlcarousel/owlcarousel.component";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactTooltip from "react-tooltip";
// import "./owlcarousel.styles.scss";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";

import pot from "../../assets/011-pot.svg";
import sprout from "../../assets/010-sprout.svg";

import olive from "../../assets/olive.svg";
import plant1 from "../../assets/Plant_1.svg";
import plant2 from "../../assets/Plant_2.svg";
import plant3 from "../../assets/Plant_3.svg";
import plant4 from "../../assets/Plant_4.svg";
import plant5 from "../../assets/Plant_5.svg";
import plant6 from "../../assets/Plant_6.svg";
import plant7 from "../../assets/Plant_7.svg";
import plant8 from "../../assets/Plant_8.svg";
import plant9 from "../../assets/Plant_9.svg";
import plant10 from "../../assets/Plant_10.svg";
import plant11 from "../../assets/Plant_11.svg";
import plant12 from "../../assets/Plant_12.svg";
import plant13 from "../../assets/Plant_13.svg";
import plant14 from "../../assets/Plant_14.svg";
import plant15 from "../../assets/Plant_15.svg";
import plant16 from "../../assets/Plant_16.svg";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  CardSubtitle,
  Button,
  Tooltip,
} from "reactstrap";

import { Container, Row, Col } from "reactstrap";

import { Parallax } from "react-parallax";
import ContattaciFooter from "../../components/contattaci-footer/contattaci-footer.component";

const Homepage = ({ t, i18n }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  var responsive = {
    0: {
      items: 3,
    },
    700: {
      items: 3,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  };

  return (
    <div className="homepage">
      <MediaQuery query="(max-width: 650px)">
        <Container style={{ margin: "0", padding: "0" }} fluid>
          <div
            className="wrapper"
            style={{
              marginTop: "50px",
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/homepage-img-mobile.jpg"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                marginBottom: "0px",
                filter: "brightness(75%)",
              }}
              alt=""
            />

            <div
              class="centered"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                className="big-title"
                style={{
                  fontWeight: "500",
                }}
              >
                {t("slogan.body")}
              </div>
              <div
                className="big-title"
                style={{
                  fontWeight: "500",
                }}
              >
                {t("slogan.body2")}
              </div>
              <Link to="/prodotti">
                <Button
                  size="sm"
                  style={{ marginTop: "5px" }}
                  className="btn-success"
                >
                  {t("home_button.body")}
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </MediaQuery>

      <MediaQuery query="(min-width: 1024px)">
        <Container style={{ margin: "0", padding: "0" }} fluid>
          <div
            className="wrapper"
            style={{
              marginTop: "50px",
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/homepage-img-desktop.jpg"
              style={{
                filter: "brightness(75%)",

                width: "100%",
                height: "auto",
                objectFit: "cover",
                marginBottom: "0px",
                maxHeight: "380px",
              }}
              alt=""
            />

            <div
              class="centered"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                className="big-title"
                style={{
                  fontSize: "3rem",
                  fontWeight: "500",
                }}
              >
                {t("slogan.body")}
              </div>
              <div
                className="big-title"
                style={{
                  fontSize: "3rem",
                  fontWeight: "500",
                }}
              >
                {t("slogan.body2")}
              </div>
              <Link to="/prodotti">
                <Button
                  size="sm"
                  style={{ marginTop: "12px" }}
                  className="btn-success"
                >
                  {t("home_button.body")}
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </MediaQuery>

      {/* <Container
        style={{
          margin: "0",
          padding: "0",
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        fluid
      >

        <div
          className="wrapper"
          style={{ position: "relative", textAlign: "center", color: "white" }}
        >
          <img
            src="https://i.ibb.co/0C1w4CM/IMG-5740x.jpg"
            style={{
              filter: "brightness(75%)",
              width: "100%",
              height: "auto",
              maxHeight: "380px",
              objectFit: "cover",
              marginBottom: "0px",
            }}
            alt=""
          />
          <div
            class="centered"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div
              className="big-title"
              style={{
                fontFamily: "Roboto Condensed",
                fontSize: "1.5rem",
                fontWeight: "700",
                textTransform: "upperCase",
              }}
            >
              {t("slogan.body")}
            </div>
            <Link to="/prodotti">
              <Button style={{ marginTop: "20px" }} className="btn-success">
                Scopri i prodotti
              </Button>
            </Link>
          </div>
        </div>
      </Container> */}
      <Container fluid style={{ margin: "0", padding: "0" }}>
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignContent: "center",
            verticalAlign: "center",
            padding: "10px 0",

            backgroundColor: "#6c8946",
            marginBottom: "30px",
          }}
        >
          <p
            className="subtitle"
            style={{
              marginBottom: "0",
              fontFamily: "Poppins",
              color: "white",
              textAlign: "center",
            }}
          >
            {t("homepage.subtitle")}
          </p>
        </div>
      </Container>
      {/* <Container fluid>
        <Row>
          <Col className="" xs="auto" md="6">
            <Container className="my-cont" style={{ margin: "0" }}>
              <img
                src="https://images.unsplash.com/photo-1597055181300-e3633a917c9c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80"
                alt=""
                style={{ padding: "", marginTop: "", borderRadius: 0 }}
                className="img-responsive"
              />
            </Container> */}
      {/* </Col>
          <Col className="" xs="auto" md="6">
            <Container style={{ padding: "0 " }}>
              <h3 style={{ marginBottom: "" }} className="title larger"> */}
      {/* {t("home.title")} */}
      {/* </h3>
              <p
                style={{
                  fontSize: "3rem",
                  color: "#5c634e",
                }}
                className=""
              ></p>
              <Col className="my-col" xs="auto" md="6"></Col>
            </Container>
          </Col>
        </Row>
      </Container> */}
      {/* SECTION2 */}
      <Container
        className="section-two"
        style={{
          marginTop: "32px",
          marginBottom: "32px",
        }}
      >
        <Row>
          <Col md="4">
            <Card className="home-card">
              <CardBody>
                <div>
                  <div className="card-div">
                    <img
                      src={plant14}
                      style={{ width: "30px", marginRight: "10px" }}
                      alt=""
                    />
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1.3rem",
                        fontWeight: "500",
                        color: " rgba(0,0,0,0.45)",
                        marginBottom: "0",
                        lineHeight: 1.1,
                      }}
                    >
                      {t("homepage.card1_title")}
                    </p>
                  </div>
                  <p
                    style={{
                      marginTop: "0.5rem",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {t("homepage.card1_body")}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="home-card">
              <CardBody>
                <div>
                  <div className="card-div">
                    <img
                      src={plant12}
                      style={{ width: "30px", marginRight: "10px" }}
                      alt=""
                    />
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1.3rem",
                        fontWeight: "500",
                        color: " rgba(0,0,0,0.45)",
                        marginBottom: "0",
                        lineHeight: 1.1,
                      }}
                    >
                      {t("homepage.card2_title")}
                    </p>
                  </div>
                  <p style={{ marginTop: "0.5rem", fontFamily: "Montserrat" }}>
                    {t("homepage.card2_body")}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="home-card">
              <CardBody>
                <div>
                  <div className="card-div">
                    <img
                      src={plant3}
                      style={{ width: "34px", marginRight: "10px" }}
                      alt=""
                    />
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1.3rem",
                        fontWeight: "500",
                        color: " rgba(0,0,0,0.45)",
                        marginBottom: "0",
                        lineHeight: 1.1,
                      }}
                    >
                      {t("homepage.card3_title")}
                    </p>
                  </div>
                  <p style={{ marginTop: "0.5rem", fontFamily: "Montserrat" }}>
                    {t("homepage.card3_body")}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Container
        style={{ margin: "0", padding: "0", backgroundColor: "#c2edda" }}
        fluid
      >
        <div style={{ height: "200px" }}></div>
      </Container> */}
      {/* <section>
        <Container fluid style={{ margin: "0", padding: "0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "300px",
              backgroundColor: "#4d6c63",
              margin: "30px 0",
              padding: "20px 20%",
              textAlign: "center",
              color: "white",
              fontSize: "30px",
            }}
          >
            SPOT
          </div>
        </Container>
      </section> */}
      {/* <Container fluid style={{ margin: " 40px 0", padding: "0" }}>
        <Row>
          <Col md="12">
            <div
              style={{
                backgroundColor: "#4d6c63",
                height: "300px",
                margin: "0",
                padding: "0",
              }}
            >
              <div
                class="centered"
                style={{
                  margin: "0",
                  padding: "0",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: "30px",
                    marginBottom: "0",
                  }}
                >
                  SPOT
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container style={{ marginBottom: "3rem" }}>
        <div className="videoWrapper">
          <iframe
            className="video-box"
            width="960px"
            height="540px"
            title="video"
            src={t("youtube.link")}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col md="12">
            <div
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "24px",
              }}
            >
              <h1
                style={{
                  color: "rgb(23, 115, 58)",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                }}
              >
                I NOSTRI PRODOTTI
              </h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* ToolTips */}
      <ReactTooltip id="oc07" place="top" effect="solid">
        Olivo Cespuglio 7
      </ReactTooltip>
      <ReactTooltip id="oa17" place="top" effect="solid">
        Olivo Alberetto 17
      </ReactTooltip>
      <ReactTooltip id="otbc21" place="top" effect="solid">
        Olivo Bonsai 21
      </ReactTooltip>
      <ReactTooltip id="oa53" place="top" effect="solid">
        Olivo Alberetto 53
      </ReactTooltip>
      <Container>
        <Row className="justify-content-center">
          <Container className="home-carousel">
            <div>
              <div class="container-fluid">
                <div
                  className="row title"
                  style={{ marginBottom: "20px" }}
                ></div>
              </div>

              <div class="container-fluid" style={{ margin: "20px 0" }}>
                <OwlCarousel
                  nav
                  responsive={responsive}
                  className="owl-theme"
                  navText={[
                    "<i class='fa fa-chevron-left'></i>",
                    "<i class='fa fa-chevron-right'></i>",
                  ]}
                >
                  <Link to="/prodotti/olivi/cespugli/OC07">
                    <div
                      data-tip
                      data-for="oc07"
                      className="item-carousel-home"
                      style={{ margin: "0 10px" }}
                    >
                      <img
                        style={{ borderRadius: "3px" }}
                        className="img-responsive"
                        src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OC07_DS7.jpg"
                        alt="OC07"
                      />
                    </div>
                  </Link>

                  <Link to="/prodotti/olivi/alberetti/OA17">
                    <div
                      data-tip
                      data-for="oa17"
                      className="item-carousel-home"
                      style={{ margin: "0 10px" }}
                    >
                      <img
                        style={{ borderRadius: "3px" }}
                        className="img-responsive"
                        src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OA17_IP16.jpg"
                        alt="OA17"
                      />
                    </div>
                  </Link>
                  <Link to="/prodotti/olivi/bonsai/OTBC21">
                    <div
                      data-tip
                      data-for="otbc21"
                      className="item-carousel-home"
                      style={{ margin: "0 10px" }}
                    >
                      <img
                        style={{ borderRadius: "3px" }}
                        className="img-responsive"
                        src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OTBC21_TC21.jpg"
                        alt="OTBC21"
                      />
                    </div>
                  </Link>
                  <Link to="/prodotti/olivi/alberetti/OA53">
                    <div
                      className="item-carousel-home"
                      style={{ margin: "0 10px" }}
                      data-tip
                      data-for="oa53"
                    >
                      <img
                        style={{ borderRadius: "3px" }}
                        className="img-responsive"
                        src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OA53.jpg"
                        alt=""
                      />
                    </div>
                  </Link>
                </OwlCarousel>
              </div>
            </div>
          </Container>
        </Row>
      </Container>
      {/* SECTION3 */}
      {/* SECTION4 */}
      {/* <section>
        <Container fluid style={{ margin: "0", padding: "0" }}>
          <div
            style={{
              height: "300px",
              backgroundColor: "#4d6c63",
              margin: "0",
              padding: "20px 20%",
            }}
          ></div>
        </Container>
      </section> */}
    </div>
  );
};

export default withRouter(withTranslation(["common"])(Homepage));
