import React from "react";
import { Container, Row, Col, CardBody, Collapse, Button } from "reactstrap";
import "./categoria.styles.scss";
import Item from "../../../components/item/item.component";
import { firestore } from "firebase";
import { db } from "../../../firebase/firebase.utils";
import { withRouter, Link } from "react-router-dom";
import { Switch } from "@material-ui/core";
import ItemDetailPage from "../../item-detail-page/item-detail-page.component";
import { Route } from "react-router-dom";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleDetails from "../../../components/toggleDetails/toggleDetails.component";
import MenuLaterale from "../../../components/menu-laterale/menu-laterale.component";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
} from "reactstrap";

import foglia from "../../../assets/foglia.svg";
import sole from "../../../assets/sun.svg";
import frutto from "../../../assets/frutto.svg";
import fiore from "../../../assets/fiore.svg";

class Categoria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prodotti: [],
      tag: this.props.match.params.prodottoID,
    };
  }

  componentDidMount() {
    if (this.state.tag === "terracotta") {
      db.collection(this.state.tag)
        .where("vetrina", "==", true)
        .get()
        .then((snapshot) => {
          const prodotti = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.docID = doc.id;
            prodotti.push(data);
          });
          this.setState({
            prodotti: prodotti,
          });
        });
    } else
      db.collection(this.state.tag)
        .get()
        .then((snapshot) => {
          const prodotti = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.docID = doc.id;
            prodotti.push(data);
          });
          this.setState({
            prodotti: prodotti,
          });
        });
  }

  render() {
    const { match, history } = this.props;

    return (
      <div className="olivi" style={{ marginTop: "78px" }}>
        {/* <p className="title" style={{ fontSize: "24px", fontFamily: "Lato" }}>
          {this.state.tag.toUpperCase()}
        </p> */}
        {/* <Container>
          <Row>
            <Col> */}
        {/*    <Container style={{ margin: "0", padding: "0" }} fluid>
          <Row>
            {(() => {
              switch (this.state.tag) {
                case "olivi":
                  return (
                    <img
                      style={{
                        borderRadius: "2px",
                        width: "100%",
                        height: "auto",
                        maxHeight: "180px",
                        objectFit: "cover",
                        marginBottom: "15px",
                      }}
                      src="https://i.ibb.co/3k5HsZn/IMG-5832xx.jpg"
                      alt=""
                    />
                  );
                case "agrumi":
                  return (
                    <img
                      style={{
                        borderRadius: "2px",
                        marginBottom: "15px",
                        width: "100%",
                        height: "auto",
                        maxHeight: "180px",
                        objectFit: "cover",
                      }}
                      src="https://i.ibb.co/516Y0ST/IMG-5801x.jpg"
                      alt=""
                    />
                  );
                case "ficus":
                  return (
                    <img
                      style={{
                        borderRadius: "2px",
                        margin: "0px 0",
                        width: "100%",
                        height: "auto",
                        maxHeight: "180px",
                        objectFit: "cover",
                        marginBottom: "15px",
                      }}
                      src="https://i.ibb.co/1KG8zcG/IMG-5736x.jpg"
                      alt=""
                    />
                  );

                default:
                  return null;
              }
            })()}
          </Row>
        </Container> */}
        <Container fluid>
          <Row noGutters>
            <Col>
              <p style={{ marginTop: "24px", padding: "0 10%" }}>
                Catalogo <i class="fas fa-angle-right"></i>
                <span>{" " + this.state.tag.toUpperCase()}</span>
              </p>
            </Col>
          </Row>
        </Container>
        {/* <Container fluid>
          <Row noGutters>
            <Col>
              <div style={{ padding: "16px 10%" }}>
                <Form>
                  <FormGroup>
                    <Row noGutters>
                      {/* <Label for="exampleSearch">Ricerca</Label> */}
        {/* <Col xs="9">
                        <Input
                          type="search"
                          name="search"
                          id="exampleSearch"
                          placeholder="Cerca tra i vasi disponibili"
                        />
                      </Col>
                      <Col xs="3">
                        <Button color="info">Cerca</Button>
                      </Col>
                    </Row> */}
        {/* </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Seleziona dimensioni</Label>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>14</option>
                      <option>16</option>
                      <option>20</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleCustomRange">Diametro vaso</Label>
                    <CustomInput
                      type="range"
                      id="exampleCustomRange"
                      name="customRange"
                    />
                  </FormGroup> */}
        {/* </Form> */}
        {/* </div> */}
        {/* </Col> */}
        {/* </Row> */}
        {/* </Container> */}
        {/* <ToggleDetails></ToggleDetails> */}
        {/* <Container>
          <Row>
            <Col md="4">
              <div
                style={{
                  backgroundColor: "rgba(0,255,0,0.08)",
                  minHeight: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  border: "solid 1px rgba(0,0,0,0.25)",
                  borderRadius: "3px",
                  padding: "0% 5%",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={sole}
                  style={{
                    width: "36px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
                <p
                  style={{
                    marginBottom: "0px",
                    alignSelf: "center",
                    fontFamily: "Lato",
                  }}
                >
                  <em>Esposizione</em> <br></br> Pieno sole o mezz’ombra.
                </p>
              </div>
            </Col>
            <Col md="3" className="">
              <div
                style={{
                  backgroundColor: "rgba(0,255,0,0.08)",
                  minHeight: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "solid 1px rgba(0,0,0,0.25)",
                  borderRadius: "3px",
                  padding: "0% 5%",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={foglia}
                  style={{
                    width: "36px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
                <p style={{ marginBottom: "0px", fontFamily: "Lato" }}>
                  <em>Esposizione</em> <br></br> Pieno sole o mezz’ombra.
                </p>
              </div>
            </Col>
            <Col md="3">
              <div
                style={{
                  backgroundColor: "rgba(0,255,0,0.08)",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "90px",
                  border: "solid 1px rgba(0,0,0,0.25)",
                  borderRadius: "3px",
                  padding: "3% 5%",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={frutto}
                  style={{
                    width: "36px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
                <p style={{ marginBottom: "0px", fontFamily: "Lato" }}>
                  <em>Fruttificazione</em> <br></br>Raccolta frutti in Autunno
                </p>
              </div>
            </Col>
            <Col md="2">
              <div
                style={{
                  backgroundColor: "rgba(0,255,0,0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "solid 1px rgba(0,0,0,0.25)",
                  borderRadius: "3px",
                  padding: "3% 5%",
                  minHeight: "90px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={fiore}
                  style={{
                    width: "36px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
                <p style={{ marginBottom: "0px", fontFamily: "Lato" }}>
                  <em>Fioritura</em>
                  <br></br> Primavera
                </p>
              </div>
            </Col>
          </Row>
        </Container> */}

        <Route
          path={`${match.url}/:collectionId`}
          exact
          component={ItemDetailPage}
        />

        <Container className="cards">
          <Row noGutters>
            {this.state.prodotti.map(({ photoUrl, nome, docID }) => (
              <Item
                key={docID}
                docID={docID}
                photoUrl={photoUrl}
                dettaglio={nome}
                linkable
              ></Item>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Categoria);
