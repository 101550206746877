import React from "react";
import "./footer.styles.scss";
import { FooterContainer } from "../footer/footer.styles";
import Social from "../social/social.component";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import "../social/social.styles.scss";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Footer = ({ t, i18n }) => (
  <div>
    {/* <hr
      className="solid"
      style={{ width: "100%", borderTop: "1px solid #dadddf" }}
    ></hr> */}
    {/* <Container fluid className="footer-container">
      <Row> */}
    {/* <Col md="12" xs="auto">
          <p
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              color: "#17733a",
            }}
          >
            AZIENDA AGRICOLA ANANIA PATRIZIA
          </p>
          <Link to="/condizioniduso">
            <p
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                color: "#17733a",
              }}
            >
              Condizioni d'uso
            </p>
          </Link>
        </Col> */}
    {/* <Col xs="auto">
          <p className="footer-address">
            Viale Sicilia II Vico 3 98057 Milazzo
          </p>
          <p>Copyright &copy; {new Date().getFullYear()} </p>
        </Col> */}
    {/* </Row> */}
    {/* <Social /> */}
    {/* </Container> */}

    {/* <Container fluid>
      <Row>
        <Col md="12">
          <Social></Social>
        </Col>
      </Row>
    </Container> */}

    {/* <Container fluid >
      <Row>
        <Col md="12">
          <p
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              color: "#17733a",
            }}
          >
            COPYRIGHT &copy; {new Date().getFullYear()}{" "}
          </p>
        </Col>
      </Row>
    </Container> */}
    <div class="footer-dark">
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-md-3 item">
              <h3>Prodotti</h3>
              <ul>
                <li>
                  <Link to="/prodotti/olivi">Olivi</Link>
                </li>
                <li>
                  <a>Agrumi</a>
                </li>
                <li>
                  <a>Ficus</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-3 item">
              <h3>About</h3>
              <ul>
                <li>
                  <Link to="/contatti">Contatti</Link>
                </li>
                <li>
                  <Link to="/azienda">Azienda</Link>
                </li>
                <li>
                  <Link to="/produzione"></Link>
                </li>
                <li>
                  <Link to="/condizioniduso">Condizioni d'uso</Link>
                </li>
              </ul>
            </div>
            <div class="col-md-6 item text">
              <h3>Vivai Anania</h3>
              <h3>
                {t("slogan.body")} {t("slogan.body2")}
              </h3>
            </div>
            <div style={{ marginTop: "2rem" }} class="col item social">
              <SocialIcon
                className="single-social"
                style={{ height: 35, width: 35, margin: "0 20px" }}
                network="facebook"
                bgColor="#fff"
                url="https://www.facebook.com/vivaianania"
                target="_blank"
              />
              <SocialIcon
                className="single-social"
                style={{ height: 35, width: 35, margin: "0 20px" }}
                network="instagram"
                bgColor="#fff"
                url="https://www.instagram.com/vivai_anania/?hl=it"
                target="_blank"
              />
              <SocialIcon
                className="single-social"
                style={{ height: 35, width: 35, margin: "0 20px" }}
                network="whatsapp"
                bgColor="#fff"
                url="https://wa.me/message/G734GH2GKKNLE1"
                target="_blank"
              />
            </div>
          </div>
          <p class="copyright">Vivai Anania © 2021</p>
        </div>
      </footer>
    </div>
  </div>
);

export default withRouter(withTranslation(["common"])(Footer));
