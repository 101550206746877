import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Item from "../../components/item/item.component";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter, Route, Link } from "react-router-dom";
import ShowMore from "react-show-more";

import {
  firestore,
  convertCollectionSnapshotToMap,
} from "../../firebase/firebase.utils";

class CategoriaOlivo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n, history, match } = this.props;

    return (
      <div className="catalogo">
        {/* <Route
          path={`${match.url}/:collectionId`}
          exact
          component={Vasi}
        /> */}

        <Container>
          <Row noGutters>
            <Col>
              <p style={{ marginTop: "24px", padding: "0 0" }}>
                <Link to="/prodotti">
                  <span>Prodotti </span>
                </Link>
                <i class="fas fa-angle-right"></i>
                <span> Olivi</span>
              </p>
              <h3 style={{ marginBottom: "1rem", color: "rgb(23, 115, 58)" }}>
                {t("olea_history.title")}
              </h3>
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row>
            <Col md="6">
              <h3>{t("welfare.title")}</h3>
              <p>{t("welfare.text")}</p>
            </Col>
          </Row>
        </Container> */}

        <Container>
          <Row class="row justify-content-center">
            <Col md="4" xs="6">
              <Container>
                <div
                  style={{ marginBottom: "20px" }}
                  className="category-wrapper"
                  onClick={() => {
                    history.push(`${match.url}${"/cespugli"}`);
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                    }}
                    TODO // Change with media query
                    className="img-responsive clickable"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OC14_DC14.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">Cespugli</span>
                  </div>
                </div>
              </Container>
            </Col>
            <Col md="4" xs="6">
              <Container>
                <div
                  style={{ marginBottom: "20px" }}
                  className="category-wrapper"
                  onClick={() => {
                    history.push(`${match.url}${"/alberetti"}`);
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                    }}
                    TODO // Change with media query
                    className="img-responsive clickable"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OA15_DCA14.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">Alberetti</span>
                  </div>
                </div>
              </Container>
            </Col>
            <Col md="4" xs="6">
              <Container>
                <div
                  style={{ marginBottom: "20px" }}
                  className="category-wrapper"
                  onClick={() => {
                    history.push(`${match.url}${"/bonsai"}`);
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "450px",
                      maxWidth: "300px",
                      borderRadius: "0",
                    }}
                    TODO // Change with media query
                    className="img-responsive clickable"
                    src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/OTBC16_DSCH16.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">Bonsai</span>
                  </div>
                </div>
              </Container>
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row>
            <Col md="12">
              <ShowMore>
                <p>{t("olea_history.text")}</p>
              </ShowMore>

              <h3 style={{ marginTop: "1rem" }}>{t("welfare.title")}</h3>
              <ShowMore>
                <p>{t("welfare.text")}</p>
              </ShowMore>
            </Col>
          </Row>
        </Container> */}
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(CategoriaOlivo));
