import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel-detail-slicker.styles.scss";
export default class CarouselSlicker extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: true,
    };
    return (
      <div className="myslider" style={{ margin: "30px 20px" }}>
        <Slider {...settings}>
          <div>
            <img
              className="img-responsive-carousel-rounded"
              src="https://images.unsplash.com/photo-1598441547299-354f678091a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=626&q=80"
              alt="Photo1"
            />
          </div>
          <div>
            <img
              className="img-responsive-carousel-rounded"
              src="https://images.unsplash.com/photo-1571059315607-7f2fd694f899?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              alt="Photo2"
            />
          </div>
          <div>
            <img
              className="img-responsive-carousel-rounded"
              src="https://images.unsplash.com/photo-1596679223294-80bcca0f47d5?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
              alt="Photo3"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
