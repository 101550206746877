import React from "react";

import "./item.styles.scss";
import CustomButton from "../../components/custom-button/custom-button.component";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Item = ({
  photoUrl,
  nome,
  dettaglio,
  docID,
  linkable,
  history,
  match,
}) => (
  <Col md="3" lg="3" xl="3" sm="6" xs="6">
    <div
      onClick={() => history.push(`${match.url}${"/" + docID}`)}
      className="item-wrapper"
    >
      {/* <img className="item-img" src={photoUrl} alt="" /> */}
      <img className="item-image" src={photoUrl} alt="" />
      <div className="subtitle">
        <p className="subtitle-text">{dettaglio}</p>
      </div>
    </div>
  </Col>
);

export default withRouter(Item);
