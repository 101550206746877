import React from "react";
import "./contattaci-footer.styles.scss";
import certlogo from "./MPS_logo.svg";
import certlogo2 from "./global-g-a-p-seeklogo.svg";
import grasp from "./grasp.svg";

import { Container, Row, Col } from "reactstrap";

const ContattaciFooter = () => {
  return (
    <Container style={{ marginBottom: "48px" }}>
      <Row className="justify-content-center">
        <Col md="9">
          <Row className="justify-content-center">
            <Col className="align-self-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://my-mps.com/"
              >
                <img alt="" className="certLogo" src={certlogo} />
              </a>
            </Col>
            <Col className="align-self-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.globalgap.org/uk_en/index.html"
              >
                <img className="certLogo" src={certlogo2} alt="" />
              </a>
            </Col>
            <Col className="align-self-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.globalgap.org/uk_en/for-producers/globalg.a.p.-add-on/grasp/What-is-GRASP/#"
              >
                <img
                  className="certLogoMano"
                  src={grasp}
                  style={{ marginTop: "10px" }}
                  alt=""
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ContattaciFooter;
