import React from "react";

import "./contact.styles.scss";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import { Container, Row, Col } from "reactstrap";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      email: "",
      testo: "",
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    // this.state({ nome: "", email: "", testo: "", oggetto: "" });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="contact">
        <Container>
          <form onSubmit={this.handleSubmit}>
            <FormInput
              placeholder="Nome"
              name="nome"
              type="text"
              value={this.state.nome}
              required
              handleChange={this.handleChange}
            />
            <label>Email</label>

            <FormInput
              name="email"
              type="email"
              value={this.state.email}
              required
              handleChange={this.handleChange}
            />
            <label>Oggetto</label>

            <FormInput
              name="oggetto"
              type="text"
              value={this.state.oggetto}
              required
              handleChange={this.handleChange}
            />

            <label>Testo</label>
            <FormInput
              name="testo"
              type="text"
              value={this.state.testo}
              required
              handleChange={this.handleChange}
            />

            <CustomButton type="submit" value="Submit">
              INVIA
            </CustomButton>
          </form>
        </Container>
      </div>
    );
  }
}

export default Contact;
