import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./azienda.styles.scss";
import Slider from "react-slick";

import ContattaciFooter from "../../components/contattaci-footer/contattaci-footer.component";

const Azienda = () => {
  const [t, i18n] = useTranslation("common");
  var settings = {
    dots: true,
    // infinite: true,
    slidesToShow: 2,
    speed: 1000,
    pauseOnFocus: true,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
  };

  return (
    <div className="page-wrapper">
      <div className="azienda">
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              <h3
                // style={{ color: "rgb(23, 115, 58)" }}
                style={{
                  fontWeight: "700",
                  fontSize: "34px",
                  fontFamily: "Ubuntu",
                  color: "#17733A",
                }}
              >
                {t("nav.azienda")}
              </h3>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col xs="auto" md="12">
              <p
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  marginBottom: "12px",
                  marginTop: "0px",
                }}
              ></p>
              <div style={{ marginBottom: "32px" }}>
                <p
                  style={{
                    marginBottom: "8px",
                    textAlign: "unset",
                    fontFamily: "Catamaran,sans-serif",
                    fontWeight: "700",
                    borderLeft: "3px solid #17733a",
                    fontSize: "1.2rem",
                    paddingLeft: "1rem",
                    textTransform: "uppercase",
                  }}
                  className="title"
                >
                  {t("nav.produzione")}
                </p>
                <p>{t("azienda_page.p1")}</p>
                <p> {t("azienda_page.p2")}</p>

                <Row>
                  <Col md="6" xs="12">
                    <Container style={{ margin: "0", padding: "0" }}>
                      <img
                        alt=""
                        style={{ borderRadius: "0", marginBottom: "16px" }}
                        className="img-responsive"
                        src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-3.jpg"
                      ></img>
                    </Container>
                  </Col>
                  <Col md="6" xs="12">
                    <Container style={{ margin: "0", padding: "0" }}>
                      <img
                        alt=""
                        style={{ borderRadius: "0", marginBottom: "16px" }}
                        className="img-responsive"
                        src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-6.jpg"
                      ></img>
                    </Container>
                  </Col>
                </Row>

                <p> {t("azienda_page.p3")}</p>
                <p> {t("azienda_page.p4")}</p>
              </div>

              <p
                style={{
                  marginBottom: "8px",
                  textAlign: "unset",
                  fontFamily: "Catamaran,sans-serif",
                  fontWeight: "700",
                  borderLeft: "3px solid #17733a",
                  fontSize: "1.2rem",
                  paddingLeft: "1rem",
                  textTransform: "uppercase",
                }}
                className="title"
              >
                {t("azienda_page.valori")}
              </p>
              <p> {t("azienda_page.p5")}</p>

              {/* <Slider {...settings} style={{ margin: "32px 5%" }}>
                <img
                  alt=""
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-5.jpg"
                ></img>

                <img
                  alt=""
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-2.jpg"
                ></img>

                <img
                  alt=""
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-4.jpg"
                ></img>
                <img
                  style={{ maxHeight: "419px" }}
                  alt=""
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi+siamo-1.jpg"
                ></img>
              </Slider> */}

              <Row>
                <Col md="6" xs="12">
                  <Container style={{ margin: "0", padding: "0" }}>
                    <img
                      alt=""
                      style={{ borderRadius: "0", marginBottom: "16px" }}
                      className="img-responsive"
                      src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-4.jpg"
                    ></img>
                  </Container>
                </Col>
                <Col md="6" xs="12">
                  <Container style={{ margin: "0", padding: "0" }}>
                    <img
                      alt=""
                      style={{ borderRadius: "0", marginBottom: "16px" }}
                      className="img-responsive"
                      src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-5.jpg"
                    ></img>
                  </Container>
                </Col>
              </Row>

              <p> {t("azienda_page.p6")}</p>
              <p> {t("azienda_page.p7")}</p>
              <p> {t("azienda_page.p8")}</p>
            </Col>
            {/* 
            <Container style={{ margin: "0", padding: "0 0" }}>
              <img
                alt=""
                style={{ borderRadius: "0", marginBottom: "16px" }}
                className="img-responsive"
                src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-1.jpg"
              ></img>
            </Container> */}

            {/* 
            <Container style={{ margin: "0", padding: "0" }}>
              <img
                alt=""
                style={{ borderRadius: "0", marginBottom: "16px" }}
                className="img-responsive"
                src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-2.jpg"
              ></img>
            </Container>
            <p>{t("azienda_page.p3")}</p>
            <Container style={{ margin: "0", padding: "0" }}>
              <img
                alt=""
                style={{ borderRadius: "0", marginBottom: "16px" }}
                className="img-responsive"
                src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-3.jpg"
              ></img>
            </Container>
            <p>{t("azienda_page.p4")}</p>
            <Container style={{ margin: "0", padding: "0" }}>
              <img
                alt=""
                style={{ borderRadius: "0", marginBottom: "16px" }}
                className="img-responsive"
                src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-4.jpg"
              ></img>
            </Container>

            <p>{t("azienda_page.p5")}</p>
            <Container style={{ margin: "0", padding: "0" }}>
              <img
                alt=""
                style={{ borderRadius: "0", marginBottom: "16px" }}
                className="img-responsive"
                src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-5.jpg"
              ></img>
            </Container>
            <p>{t("azienda_page.p6")}</p>
            <p>{t("azienda_page.p7")}</p>
            <Container style={{ margin: "0", padding: "0" }}>
              <img
                alt=""
                style={{ borderRadius: "0", marginBottom: "16px" }}
                className="img-responsive"
                src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-6.jpg"
              ></img>
            </Container>

            <p>{t("azienda_page.p8")}</p> */}
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md="6" xs="12">
              <Container style={{ margin: "0", padding: "0" }}>
                <img
                  alt=""
                  style={{ borderRadius: "0", marginBottom: "16px" }}
                  className="img-responsive"
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi+siamo-1.jpg"
                ></img>
              </Container>
            </Col>
            <Col md="6" xs="12">
              <Container style={{ margin: "0", padding: "0" }}>
                <img
                  alt=""
                  style={{ borderRadius: "0", marginBottom: "16px" }}
                  className="img-responsive"
                  src="https://vivaiananiaproducts.s3-eu-west-1.amazonaws.com/chi-siamo-2.jpg"
                ></img>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "24px",
                }}
              >
                <h1
                  style={{
                    color: "rgb(23, 115, 58)",
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    margin: "20px 0",
                  }}
                >
                  {t("azienda_page.certificazioni_title")}
                </h1>
              </div>
              <div>
                <p style={{ lineHeight: "1.7" }}>
                  {t("azienda_page.certificazioni_body")}
                </p>
                <Container style={{ margin: "0", padding: "0" }}>
                  <img
                    alt=""
                    style={{ borderRadius: "0", marginBottom: "16px" }}
                    className="img-responsive"
                    src=""
                  ></img>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
        <ContattaciFooter></ContattaciFooter>
      </div>
    </div>
  );
};

export default Azienda;
