import React from "react";
import "./condizioniduso.styles.scss";
import { Container, Row, Col } from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter, Route, Link } from "react-router-dom";

class Condizioniduso extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <div style={{ marginBottom: "32px" }} className="condizioni">
        <Container>
          <Row>
            <Col>
              <div style={{ marginBottom: "32px" }}>
                <h3>{t("general_terms.title_1")}</h3>
                <h5>{t("general_terms.title_2")}</h5>
              </div>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p1_title")}
              </p>
              <p>{t("general_terms.p1_1")}</p>
              <p>{t("general_terms.p1_2")}</p>
              <p>{t("general_terms.p1_3")}</p>
              <p>{t("general_terms.p1_4")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p2_title")}
              </p>
              <p>{t("general_terms.p2_1")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p3_title")}
              </p>
              <p>{t("general_terms.p3_1")}</p>
              <p>{t("general_terms.p3_2")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p4_title")}
              </p>
              <p>{t("general_terms.p4_1")}</p>
              <p>{t("general_terms.p4_2")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p5_title")}
              </p>
              <p>{t("general_terms.p5_1")}</p>
              <p>{t("general_terms.p5_1_1")}</p>
              <p>{t("general_terms.p5_1_2")}</p>
              <p>{t("general_terms.p5_1_3")}</p>
              <p>{t("general_terms.p5_1_4")}</p>
              <p>{t("general_terms.p5_1_5")}</p>
              <p>{t("general_terms.p5_1_6")}</p>
              <p>{t("general_terms.p5_2")}</p>
              <p>{t("general_terms.p5_3")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p6_title")}
              </p>
              <p>{t("general_terms.p6_1")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p7_title")}
              </p>
              <p>{t("general_terms.p7_1")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p8_title")}
              </p>
              <p>{t("general_terms.p8_1")}</p>
              <p>{t("general_terms.p8_2")}</p>
              <p style={{ fontWeight: "bold" }}>
                {t("general_terms.p9_title")}
              </p>
              <p>{t("general_terms.p9_1")}</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(Condizioniduso));
