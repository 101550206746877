import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_it from "./translations/it/common.json";
import common_fr from "./translations/fr/common.json";

import LanguageDetector from "i18next-browser-languagedetector";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import allReducers from "./reducers";
import { devToolsEnhancer } from "redux-devtools-extension";

const store = createStore(
  allReducers,
  /* preloadedState, */ devToolsEnhancer()
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
);
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  // lng: "it", // language to use
  debug: true,
  resources: {
    it: {
      common: common_it,
    },
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
    fr: {
      common: common_fr,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
