import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Item from "../../components/item/item.component";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter, Route, Link } from "react-router-dom";

import {
  firestore,
  convertCollectionSnapshotToMap,
} from "../../firebase/firebase.utils";

class Vasi extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n, history, match } = this.props;

    return (
      <div className="catalogo">
        <Route path={`${match.url}/:collectionId`} exact component={Vasi} />

        <Container>
          <Row noGutters>
            <Col>
              <p style={{ marginTop: "24px", padding: "0 0" }}>
                <Link to="/catalogo">
                  <span>Catalogo </span>
                </Link>
                <i class="fas fa-angle-right"></i>
                <span> Vasi</span>
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="row justify-content-start">
            <Col md="6" xs="6" className="text-center">
              <Container>
                <div
                  style={{ marginBottom: "20px" }}
                  className="category-wrapper"
                  onClick={() => {
                    history.push(`${match.url}${"/terracotta"}`);
                  }}
                >
                  <img
                    alt=""
                    className="img-responsive clickable"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "0",
                      maxWidth: "300px",
                      maxHeight: "300px",
                    }}
                    src="https://i.ibb.co/hs2ctQ2/VASO-16-OLEA-BASSO-button.jpg"
                  />
                  <div className="category-label">
                    <span className="text-label">Terracotta</span>
                  </div>
                </div>
              </Container>
            </Col>

            <Col className="text-center" md="6" xs="6">
              <Container>
                <div
                  style={{ marginBottom: "20px" }}
                  className="category-wrapper"
                  onClick={() => {
                    history.push(`${match.url}${"/plastica"}`);
                  }}
                >
                  <img
                    className="img-responsive clickable"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "0",
                      // border: "0.5px solid rgba(0,0,0,0.15)",
                      maxWidth: "300px",
                      maxHeight: "300px",
                    }}
                    src="https://i.ibb.co/bsb8s0p/VASO-20-arancio-button.jpg"
                    alt="Photo7"
                  />
                  <div className="category-label">
                    <span className="text-label">Plastica</span>
                  </div>
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(withTranslation(["common"])(Vasi));
